import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// services
import { BaseHttpService } from '@shared/services/base-http.service';

// models
import { IUser } from '@shared/models/user.model';
import { PaginationResponseModel } from '@shared/models/http.models';
import { IUsersQueryParams } from '@shared/models/users-query-params.model';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseHttpService<IUser> {
  private readonly URL = 'users';

  constructor(http: HttpClient) {
    super(http);
  }

  public getUsers(params?: IUsersQueryParams): Observable<PaginationResponseModel<IUser>> {
    return this.getByPagination(this.URL, params);
  }
}
