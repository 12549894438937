import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// environment
import { environment } from 'src/environments/environment';

// models
import { IHttpOptions, PaginationResponseModel } from '@shared/models/http.models';
import { IQueryParam } from '@shared/models/query-param.model';

// helpers
import { anyToHttpParams } from '@shared/utils/any-to-http-params.helper';

export abstract class BaseHttpService<T> {

  protected readonly HOST: string = environment.HOST;
  protected readonly API_V1: string = environment.API_V1;

  constructor(protected readonly http: HttpClient) {}

  protected getById<T>(url: string, id: number, headers?: HttpHeaders): Observable<T> {
    return this.http.get<T>(`${this.HOST}/${this.API_V1}/${url}/${id}/`, {
      headers
    });
  }

  protected getByPagination<T>(url: string, params?: IQueryParam): Observable<PaginationResponseModel<T>> {
    if (!params) {
      params = {
        page: 1,
        pageSize: 10
      };
    }

    return this.http.get<PaginationResponseModel<T>>(`${this.HOST}/${this.API_V1}/${url}/`, {
      params: anyToHttpParams(params)
    });
  }

  protected getSingle<T>(url: string, params?: IQueryParam, headers?: HttpHeaders): Observable<T> {
    return this.http.get<T>(`${this.HOST}/${this.API_V1}/${url}/`, {
      params: anyToHttpParams(params),
      headers
    });
  }

  protected getAll<T>(url: string, params?: IQueryParam): Observable<PaginationResponseModel<T>> {
    return this.http.get<PaginationResponseModel<T>>(`${this.HOST}/${this.API_V1}/${url}/`, {
      params: anyToHttpParams({ ...params, all: true })
    });
  }

  protected post<DataType, ResponseType>(url: string, body: DataType | T, params?: IQueryParam): Observable<ResponseType> {
    return this.http.post<ResponseType>(`${this.HOST}/${this.API_V1}/${url}/`, body, {
      params: anyToHttpParams(params)
    });
  }

  protected patch<DataType, ResponseType = T>(url: string, body: DataType | T, options?: IHttpOptions): Observable<ResponseType> {
    return this.http.patch<ResponseType>(`${this.HOST}/${this.API_V1}/${url}/`, body, options);
  }

  protected put<DataType>(url: string, body: DataType | T): Observable<T> {
    return this.http.put<T>(`${this.HOST}/${url}/`, body);
  }

  protected delete<T>(url: string, options?: IHttpOptions): Observable<T> {
    return this.http.delete<T>(`${this.HOST}/${this.API_V1}/${url}/`, options);
  }

  protected deleteById<T = void>(url: string, id: number, params?: IQueryParam): Observable<T> {
    return this.http.delete<T>(`${this.HOST}/${this.API_V1}/${url}/${id}/`, {
      params: anyToHttpParams(params)
    });
  }

  protected downloadFile(url: string, fileName: string, actionType: 'download' | 'openInNewTab' = 'download', queryParams?: IQueryParam): Observable<void> {
    return this.http.get(`${this.HOST}/${this.API_V1}/${url}/`, { responseType: 'blob', params: anyToHttpParams(queryParams) })
      .pipe(map(response => {
        this.fileHandler(response, actionType, fileName)
      }));
  }

  protected downloadFileWithPost(url: string, fileName: string, body: any, actionType: 'download' | 'openInNewTab' = 'download', queryParams?: IQueryParam): Observable<void> {
    return this.http.post(`${this.HOST}/${this.API_V1}/${url}/`, body, { responseType: 'blob', params: anyToHttpParams(queryParams) })
        .pipe(map(response => {
          this.fileHandler(response, actionType, fileName)
        }));
  }

  private fileHandler(response: any, actionType: 'download' | 'openInNewTab', fileName: string) {
    const file = new Blob([response], { type: response.type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(file)
    } else {
      const href = window.URL.createObjectURL(file);
      console.log('href', href)
      if (navigator.userAgent.toLowerCase().indexOf("firefox") != -1 && actionType === 'openInNewTab') {
        const newWin = window.open(href, '_blank', fileName);
        console.log('newWin',newWin)
        if(!newWin || newWin.closed || typeof newWin.closed=='undefined') {
          alert('Pop-up Blocker is enabled! Please add this site to your exception list.')
        }
        return;
      }
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.style.display = "none";
      downloadLink.href = href;
      if (actionType === 'download') {
        downloadLink.download = fileName;
      } else {
        downloadLink.target = '_blank';
      }
      downloadLink.click();
      // URL.revokeObjectURL(href)
      if (!!downloadLink) {
        downloadLink.remove();
      }
    }
  }

}
