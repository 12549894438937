import { Injectable } from '@angular/core';

// models
import { Workspaces } from '@shared/models/resource-types.model';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public readonly simulatingUserKey: string = 'simulatingUserID';

  public getToken(): string {
    const token = localStorage.getItem('token');
    if (token) return token;
    else return '';
  }

  public setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  public get userType(): Workspaces {
    return localStorage.getItem('userType') as Workspaces;
  }

  public setUserType(userType: Workspaces): void {
    localStorage.setItem('userType', userType);
  }

  public setVisitedRoutes(visitedRoutes) {
    localStorage.setItem('visitedRoutes', JSON.stringify(visitedRoutes));
  }
  public getVisitedRoutes() {
    return JSON.parse(localStorage.getItem('visitedRoutes'));
  }
  public get getSimulationUserID(): number {
    const id = +localStorage.getItem(this.simulatingUserKey);
    return id && id > 0 ? id : null;
  }

  public set setSimulationUserID(id: number) {
    localStorage.setItem(this.simulatingUserKey, `${id}`);
  }

  public removeSimulationUserID(): void {
    localStorage.removeItem(this.simulatingUserKey);
  }

  public clear(): void {
    // token, ctmrLanguage, userType, current_student_edit_options
    localStorage.clear();
  }
}
