import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum StudentCardNameTypes {
  first_name_last_name = 'first_name_last_name',
  last_name_first_name = 'last_name_first_name',
  first_name_last_initial = 'first_name_last_initial',
  last_name_first_initial = 'last_name_first_initial'
}

export function getStudentCardNameTypesDisplayValues(translationService?: TranslationService): {[key in StudentCardNameTypes]: string} {
  if (translationService) {
    return {
      [StudentCardNameTypes.first_name_last_name]: translationService.translate(`studentCardNameTypes.${StudentCardNameTypes.first_name_last_name}`),
      [StudentCardNameTypes.last_name_first_name]: translationService.translate(`studentCardNameTypes.${StudentCardNameTypes.last_name_first_name}`),
      [StudentCardNameTypes.first_name_last_initial]: translationService.translate(`studentCardNameTypes.${StudentCardNameTypes.first_name_last_initial}`),
      [StudentCardNameTypes.last_name_first_initial]: translationService.translate(`studentCardNameTypes.${StudentCardNameTypes.last_name_first_initial}`)
    };
  } else {
    return {
      [StudentCardNameTypes.first_name_last_name]: 'First Name, Last Name',
      [StudentCardNameTypes.last_name_first_name]: 'Last Name, First Name',
      [StudentCardNameTypes.first_name_last_initial]: 'First Name Last Initial',
      [StudentCardNameTypes.last_name_first_initial]: 'Last Name, First Initial'
    };
  }
}
