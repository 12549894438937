import { SchoolStatuses } from '@school/models/school-statuses.model';
import { UserRoles } from '@shared/models/user-types.model';
import { SupportPlanPermissions } from '@school/models/teacher-support-plan-permissions.model';
import { Months } from '@shared-pages/system-defaults/models/student-data/month.model';
import { CategoryResultTypes } from '@shared-pages/system-defaults/models/student-data/student-data-result-types.model';
import { DataSourceDataTypes } from '@shared-pages/system-defaults/models/student-data/resource-data-types.model';
import { MeetingPanelTypes } from '@shared-pages/boards/models/meeting-panel-names.model';
import { Gender } from '@shared/models/gender.model';
import { StudentCardOrganizationFormats } from '@shared-pages/boards/models/student-card-organization.model';
import { StudentCardDisplayTypes } from '@shared-pages/boards/models/student-card-display-types.model';
import { StudentCardNameTypes } from '@shared-pages/boards/models/student-card-name-types.model';
import { FilterOperators } from '@shared/components/filter-students/models/filter-operators.model';
import { SupportTypes } from '@shared-pages/supports/models/support-types.model';
import { SchoolProfilePanelsTypes } from "@district/modules/school-profile/models/school-profile-panels-names.model";
import { ActionPanelTypes } from "@shared-pages/actions/models/action-panel-types.model";
import { InformationEntryTypes } from '@shared-pages/plans/modules/configurations/models/information-entry-types.model';
import { IppTableColumns } from '@shared-pages/plans/models/ipp-table-columns.model';
import { ActionTableColumnsModel } from "@shared-pages/actions/models/action-table-columns.model";
import { ActionStatusTypes } from "@shared-pages/actions/models/action-status-types.model";
import {SummariesTypes} from "@shared/components/dialogs/user-settings/models/summaries-types.model";

export function getEnglishTranslations(): any {
  return {
    APP_NAME: 'CTMR',
    pages: {
      auth: {
        main: {
          title: 'Designed by Educators for Educators',
          description: '<span class="color-main-blue">We</span><span class="color-main-orange">Collab</span> is the tool that empowers schools to provide the best response for each and every child.',
          googleSignIn: 'Sign in With Google',
          microsoftSignIn: 'Sign in With Microsoft',
          emailSignIn: 'Sign in with Email'
        },
        partnersList: {
          title: 'Our Partners',
          subTitle: 'Interested in learning more about the <span class="color-main-blue">We</span><span class="color-main-orange">Collab</span> system?',
          requestInformation: 'Request Information',
          watchVideo: 'Watch Video'
        },
        testimonials: {
          we: 'We',
          collab: 'Collab',
          testimonials: 'Testimonials'
        },
        footer: {
          copyright: 'COPYRIGHT',
          copyrightRights: 'JIGSAW LEARNING INC. ALL RIGHTS RESERVED.',
          termsAndConditions: 'Terms and Conditions',
          privacyPolicy: 'Privacy Policy',
          poweredBy: 'Powered by ',
          STDev: 'STDev'
        },
        header:{
         learnMore:'Learn More',
         freeTrial:'Free Trial',
         scheduleConversation:'Schedule a Conversation'
        },
        suspendedAccountWarning: 'Disabled schools are currently suspended',
        agreementTitle: 'User Agreement',
        agree: 'Agree',
        disagree: 'Disagree',
        exit: 'Exit',
        disagreeDialogMessage: 'Exiting without agreeing to the user agreement will result in being signed off the system․',
      }
    },
    noData: 'No Data',
    noFieldValue: 'N/A',
    noItems: 'No items found',
    noIcons: 'There are no icons',
    notAvailable: 'Not Available',
    totalStudents: 'Total Students',
    doNotAutoDelete: 'Do Not Auto-Delete',
    noPermission: 'You don\'t have permissions to view this page',
    addStudentsToSchoolDescription: 'To return students to the active roster, select student(s) and then press "Add to School".',
    pagination: {
      first: 'First',
      last: 'Last',
      showing: 'Showing',
      of: 'of',
      notFound: 'No result is found'
    },
    name: 'Name',
    type: 'Type',
    date: 'Date',
    documents: 'Documents',
    noDocumentsAdded: 'There are no documents added',
    noLinksAdded: 'There are no links added',
    webLinks: 'Web Links',
    loading: 'Loading...',
    current: 'Current',
    past: 'Past',
    access: 'Access Permissions',
    labels: {
      principal: 'Principal',
      users: 'Users',
      district: 'District',
      focusName: 'Focus Name',
      goal: 'Goal',
      deletedAttendances: 'Deleted Attendances',
      deletedRecipients: 'Deleted Recipients',
      otherSchoolAttendances: 'Attendees From Another School',
      activeUsers: 'Active Users',
      activeDistricts: 'Active Districts',
      activeSchools: 'Active Schools',
      activeStudents: 'Active students',
    },
    tables: {
      move: 'Move',
      role: 'Role',
    },
    schoolStatuses: {
      [SchoolStatuses.good]: 'Active',
      [SchoolStatuses.payment_pending]: 'Pending',
      [SchoolStatuses.account_suspended]: 'Suspended'
    },
    userTypes: {
      [UserRoles.District_Leader]: 'District Leader',
      [UserRoles.District_Staff]: 'District Staff',
      [UserRoles.District_Admin]: 'District Admin',
      [UserRoles.School_Teacher]: 'School Teacher',
      [UserRoles.School_Leader]: 'School Leader',
      [UserRoles.School_Staff]: 'School Staff',
      [UserRoles.School_Admin]: 'School Admin'
    },
    summariesTypes: {
      [SummariesTypes.Student_Search]: 'Student Search',
      [SummariesTypes.Team_Boards]: 'Team Boards',
      [SummariesTypes.Collab_Meetings]: 'Collaborative Team Meetings',
      [SummariesTypes.User_Actions]: 'User Actions',
      [SummariesTypes.School_Actions]: 'School Actions',
      [SummariesTypes.Active_Support_Plans]: 'Active Support Plans',
      [SummariesTypes.Inactive_Support_Plans]: 'Deactivated Support Plans',
    },
    supportPlanPermissions: {
      [SupportPlanPermissions.NO_SELECT]: 'Not Set',
      [SupportPlanPermissions.IPP_COORDINATOR]: 'Support Plan Coordinator',
      [SupportPlanPermissions.IPP_ADMIN]: 'Support Plan Admin',
      [SupportPlanPermissions.IPP_TEACHER]: 'Support Plan Teacher',
    },
    studentCardOrganizationFormats: {
      [StudentCardOrganizationFormats.first_name]: 'First Name - Organize cards by student first name in each tier',
      [StudentCardOrganizationFormats.last_name]: 'Last Name - Organize cards by student last name in each tier',
      [StudentCardOrganizationFormats.grade]: 'Grade - Organize cards by student grade in each tier',
      [StudentCardOrganizationFormats.manual]: 'Manual - Allow user to organize student card order in each tier'
    },
    studentCardDisplayTypes: {
      [StudentCardDisplayTypes.name]: 'Name Only',
      [StudentCardDisplayTypes.minimal_name_bar]: 'Minimal Name Bar',
      [StudentCardDisplayTypes.picture]: 'Picture Only',
      [StudentCardDisplayTypes.name_picture]: 'Name and Picture',
      [StudentCardDisplayTypes.name_picture_data]: 'Name, Picture and Data',
      [StudentCardDisplayTypes.name_data]: 'Name and Data',
    },
    studentCardNameTypes: {
      [StudentCardNameTypes.first_name_last_name]: 'First Name, Last Name',
      [StudentCardNameTypes.last_name_first_name]: 'Last Name, First Name',
      [StudentCardNameTypes.first_name_last_initial]: 'First Name Last Initial',
      [StudentCardNameTypes.last_name_first_initial]: 'Last Name, First Initial'
    },
    months: {
      [Months.January]: 'January',
      [Months.February]: 'February',
      [Months.March]: 'March',
      [Months.April]: 'April',
      [Months.May]: 'May',
      [Months.June]: 'June',
      [Months.July]: 'July',
      [Months.August]: 'August',
      [Months.September]: 'September',
      [Months.October]: 'October',
      [Months.November]: 'November',
      [Months.December]: 'December'
    },
    studentDataResultTypes: {
      [CategoryResultTypes.manual]: 'Manual',
      [CategoryResultTypes.dropdown]: 'Dropdown'
    },
    resourceDataTypes: {
      [DataSourceDataTypes.label]: 'Data Label',
      [DataSourceDataTypes.month]: 'Administration Month'
    },
    genders: {
      [Gender.MALE]: 'Male',
      [Gender.FEMALE]: 'Female',
      [Gender.OTHER]: 'Other'
    },
    filterOperators: {
      [FilterOperators.more_than]: 'More than',
      [FilterOperators.less_than]: 'Less than',
      [FilterOperators.equal]: 'Equal to',
      [FilterOperators.not_equal]: 'Not equal to',
      [FilterOperators.contain]: 'Contains',
      [FilterOperators.not_contain]: 'Not contain'
    },
    studentSupportTypes: {
      [SupportTypes.accommodation]: 'Accommodation',
      [SupportTypes.strategy]: 'Strategy',
      [SupportTypes.intervention]: 'Intervention'
    },
    informationEntryTypes: {
      [InformationEntryTypes.manual]: 'Manual',
      [InformationEntryTypes.sis]: 'SIS Field'
    },
    ippTableColumns: {
      [IppTableColumns.STUDENT_NAME]: 'Student name',
      [IppTableColumns.TEMPLATE]: 'Template',
      [IppTableColumns.SCHOOL]: 'School',
      [IppTableColumns.TEACHER]: 'Teacher',
      [IppTableColumns.LAST_REVISION]: 'Last Revision',
      [IppTableColumns.SIGNATURES]: 'Signatures',
      [IppTableColumns.DEACTIVATION_DATE]: 'Deactivated Date'
    },
    actionTableColumns: {
      [ActionTableColumnsModel.ACTION]: 'Action',
      [ActionTableColumnsModel.RESPONSIBLE]: 'Responsible',
      [ActionTableColumnsModel.EXPECTED_DATE]: 'Expected Date',
      [ActionTableColumnsModel.ACTUAL_DATE]: 'Actual Date',
      [ActionTableColumnsModel.STATUS]: 'Status',
      [ActionTableColumnsModel.STUDENT]: 'Student',
      [ActionTableColumnsModel.SCHOOL]: 'School',
      [ActionTableColumnsModel.MEETING_NOTE]: 'Meeting/Meeting Note',
      [ActionTableColumnsModel.COMMENT]: 'Comment',
    },
    actionStatusTypes: {
      title: 'Action Status',
      [ActionStatusTypes.incompleteInProgress]: 'Incomplete/In Progress',
      [ActionStatusTypes.incomplete]: 'Incomplete',
      [ActionStatusTypes.inProgress]: 'In Progress',
      [ActionStatusTypes.complete]: 'Complete',
      [ActionStatusTypes.abandoned]: 'Abandoned',
    },
    responses: {
      notFound: 'Not Found',
      internalServerError: 'Internal Server Error',
      unknown: 'Unknown error occurred',
      invalidPage: 'Invalid page, returning to page 1',
      success: 'Success',
      created: 'Created',
      updated: 'Updated',
      pdfError: 'Could not download PDF',
      fileDownloadError: 'Could not download file',
      couldNotPerformAction: 'Could not perform action',
      failure: 'The process is interrupted.',
      couldNotGetData: 'Could not get data',
      couldNotUploadFileCheckInputs: 'Could not upload file. Please check your inputs',
      teamBoardCreated: 'Team board created successfully',
      teamBoardUpdated: 'Team board updated successfully',
      teamBoardCreateError: 'Could not create team board',
      teamBoardUpdateError: 'Could not update team board',
      statistics: 'Could not get statistics',
      successZipUpload: 'Your upload is in progress. We will notify you via email once it\'s completed.'
    },
    fields: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      user: 'User',
      search: 'Search',
      selectSchool: 'Select School',
      selectSchools: 'Select Schools',
      selectDate: 'Select Date',
      selected: 'selected',

      grade: 'Grade',
      studentName: 'Student Name',
      schoolName: 'School Name',
      gender: 'Gender',
      studentNumber: 'Student Number',
      studentSisNumber: 'Student SIS Number',
      graduatedYear: 'Graduated Year',
      archiveDate: 'Archive Date',

      permission: 'Permission',

      addNote: 'Add Note',

      selectFolder: 'Select Folder',

      addfile: 'Add File',
      editfile: 'Edit File',
      addlink: 'Add Link',
      editlink: 'Edit Link',
      file: 'File',
      link: 'Link',
      fileName: 'File Name',
      webLinkName: 'Web Link Name',
      url: 'URL',

      selectStaff: 'Select Staff',
      selectAttendees: 'Select Attendees',
      staffGroups: 'Staff Groups',
      individualUsers: 'Individual Users',
      externalTeamMembers: 'External Team Members',

      comment: 'Comment',

      usersWithoutGroups: 'Users without groups',
      selectTeamBoard: 'Select Team Board',

      expectedCompletionDate: 'Expected Completion Date',
      school: 'School',

      selectGender: 'Select Gender',
      selectGrade: 'Select Grade',

      availableStudents: 'Available Students',
      selectedStudents: 'Selected Students',
      availableSchools: 'Available Schools',
      selectedSchools: 'Selected Schools',

      nextReviewDate: 'Next Review Date',

      selectedAttendees: 'Selected Attendees',

      emailOptional: 'Email (Optional)',
      emailRequired: 'Email (Required)',

      and: 'and',
      or: 'or',

      entryLevel: 'Student Entry Level',
      tier: 'Tier of Support',

      teachers: 'Select Teachers',
      boards: 'Select Team Boards',

      boardName: 'Board Name',
      focus: 'Focus',
      searchStudent: 'Search Student',
      searchSchool: 'Search School',
      selectNote: 'Select Student Programming Note',
      programmingNote: 'Student Programming Note',
      selectCardIcon: 'Select Student Card Icon',
      studentAlerts: 'Student Alerts',
      schoolAlerts: 'School Alerts',

      teacher: 'Teacher',

      customizeTable: 'Customize Table',

      signature: 'Signature',

      selectStudentSupportType: 'Select Student Support type',

      responsible: 'Responsible',
      completionDate: 'Completion Date',
      startDate: 'Start Date',
      endDate: 'End Date',
      reviewDate: 'Review Date',
      rating: 'Rating',

      allowedFileTypes: 'Allowed file types',

      editCurrentStudents: 'Edit Current Student(s)',
      uploadMultiplePhotos: 'Upload Multiple Photos',
      multipleStudentDataEntry: 'Multiple Student Data Entry',
      manageStudentSubscription: 'Manage Student Subscription',

      createdBy: 'Created By',

      districtAlert: 'District Alert',
      schoolAlert: 'School Alert',

      filterWarning: 'Students data has been changed. Click "Apply Filters" to see filtered results.',

      selectTeamBoardCategories: 'Select Team Board Categories',
      selectGroups: 'Select Groups',
      selectUsers: 'Select Users',
      addBoardCategory: 'Add Board Category',
      upTo20Permitted: '(up to 20 categories permitted)',
      maximumItemsTooltip: 'Maximum 20 items can be added',

      color: 'Color',
      title: 'Title',
      subtitle: 'Subtitle',
      subtitleOptional: 'Category Sub-Title (optional)',
      default: 'Default',
      teamBoardDefaultIsRequired: 'Please choose Default Team Board Category',
      boardCategoriesAreEmpty: 'Please add at least one Team Board Category',

      additionalTasks: 'Additional tasks',
      norms: 'Norms',
      normsUncheck: 'Please note that in the case of editing/deleting, changes will only affect upcoming meetings.',
      action: 'Action',
      addNorms: 'Add Norms',
      uncheckAddNormsWarning: 'Please note that the Norms have been used in the meeting(s). Are you sure you want to remove it for the upcoming meetings?',

      actions: 'Actions',
      status: 'Status',

      selectCategory: 'Select Category',
      selectTeacher: 'Select Teacher',

      selectRecipients: 'Select Recipients',

      selectStudents: 'Select Student(s)',
      selectAdditionalStudents: 'Select Additional Students',
      includedInAMeeting: 'Meeting Note Added',

      additionalNotes: 'Additional Notes',
      selectSupportToSeeStudents: 'Select Support to see students list',

      addStudentCelebrations: 'Add Student Celebrations',
      addSchoolCelebrations: 'Add School Celebrations',
      celebration: 'Celebration',
      studentPlural: 'Student(s)',
      schoolPlural: 'School(s)',
      keyIssue: 'Key Issue',
      context: 'Context',
      selectAdditionalSchools: 'Select Additional Schools',
      linkToSupportPlanSubGoal: 'Link to Support Plan Sub-Goal',
      usersResponsible: 'User(s) Responsible',
      selectFocus: 'Select Focus',
      selectSupport: 'Select Support'
    },
    errorMessages: {
      noFileSelected: 'Please select a file',
      noCsvFileSelected: 'Please select a CSV file',
      selectStudent: 'Please select at least one student',
    },
    formInputErrors: {
      min: 'Value must be greater than or equal to',
      max: 'Value must be less than or equal to',
      required: 'Required',
      notUnique: 'Not unique',
      email: 'Invalid email',
      minLength: 'Please enter at least {minlength} characters',
      maxLength: 'Please enter maximum {maxlength} characters',
      exists: 'Exists',
      onlyNumbers: 'Please enter only numbers',
      emailAlreadyUsed: 'Some of emails are already used or are invalid',
      invalidPhoneNumber: 'Invalid phone number',
      invalidFileType: 'Invalid file type',
      maxAllowedFileSize: 'File size should be up to {maxAllowedFileSize} MB',
      matDatepickerParse: 'Invalid date format',
      matDatepickerFilter: 'This date cannot be selected',
      dateNotAllowed: 'This date cannot be selected',
      googleDocURL: 'Please make sure to provide a valid Google Doc URL',
      video: 'Please make sure to provide a valid Youtube video URL',
    },
    errorTexts: {
      row: 'Row',
      field: 'Field',
      errors: 'Errors'
    },
    expansionPanels: {
      collapse: 'Collapse',
      expand: 'Expand',
      expandAll: 'Expand All',
      shrinkAll: 'Collapse All'
    },
    image: {
      upload: 'Select Image',
      change: 'Change Image',
      dnd: ' or Drag/Drop here'
    },
    buttons: {
      save: 'Save',
      saveAndContinue: 'Save And Continue',
      cancel: 'Cancel',
      view: 'View',
      add: 'Add',
      edit: 'Edit',
      new: 'New',
      addNew: 'Add New',
      close: 'Close',
      andAndAddAnother: 'Save and Add Another',
      selectAll: 'Select All',
      deselectAll: 'Deselect All',
      yes: 'Yes',
      no: 'No',
      addMore: 'Add More',
      continue: 'Continue',
      uploadFile: 'Upload file',
      changeFile: 'Change File',
      import: 'Import',
      approve: 'Approve',
      reject: 'Reject',
      addFolder: 'Add Folder',
      editFolder: 'Edit Folder',
      addResource: 'Add Resource',
      editResource: 'Edit Resource',
      addToSchool: 'Add to School',
      delete: 'Delete',
      start: 'Start',
      schedule: 'Schedule',
      addNewAction: 'Add New Action',
      viewPDF: 'View PDF',
      exportCSV: 'Export CSV',
      exportPDF: 'Export PDF',
      teamBoardAccess: 'Team Board Access',
      resetData: 'Reset Data',
      clearFilter: 'Clear Filter',
      applyFilter: 'Apply Filter',
      saveAndView: 'Save and View',
      previewPDF: 'Preview PDF',
      next: 'Next',
      previous: 'Previous',
      remove: 'Remove',
      returnToTeamBoard: 'Return to Team Board',
      endMeeting: 'End Meeting',
      switchToProfile: 'Switch to Profile',
      switchToRecord: 'Switch to Record',
      addToContext: 'Add To Context',
      generatePDF: 'Generate PDF',
      generateCSV: 'Generate CSV',
    },
    sidebarNavigation: {
      welcome: 'Welcome',
      support: 'Support',
      feedback: 'Feedback',
      reportAnIssue: 'Report an Issue',
      poweredBy: 'Powered By',
      globalNavigationItems: {
        superOptions: 'Super Options',
        districtOptions: 'District Options',
        schoolOptions: 'School Options'
      },
      subsidiaryOf: 'A subsidiary of'
    },
    tooltips: {
      isEditing: 'is Editing',
      unlockAndEdit: 'Unlock and Edit',
      scheduled: 'Scheduled',
      unlock: 'Unlock',
      lock: 'Lock',
      locked: 'Locked',
    },
    notifications: {
      unlocked: 'One of the leaders unlocked the page. Please note that your changes won’t be saved.',
      editingByAnother: 'This page is being edited by another user',
      copyToClipboard:"Copied to clipboard"
    },
    pageNames: {
      systemStatisticsOverview: 'System Statistics Overview',
      schoolAccount: 'School Account',
      districtAccount: 'District Account',
      defaults: 'Defaults',
      supportLinks: 'Support Links',
      searchUsers: 'Search Users',
      accountCredentials: 'Account Credentials',
      connectedDistricts: 'Connected Districts',
      editUserEmails: 'Edit User Emails',
      studentSupports: 'Student Supports',
      studentSupportNetwork: 'Student Support Network',
      viewStudentSupport: 'View student support',
      importStudentSupport: 'Import a student support',
      addStudentSupport: 'Add Student Support',
      editStudentSupport: 'Edit Student Support',
      editNewStudentSupport: 'Edit New Student Support',
      home: 'Home',
      addSliderData: 'Add Slider Data',
      updateSliderData: 'Update Slider Data',
      addTestimonialData: 'Add Testimonial Data',
      updateTestimonialData: 'Update Testimonial Data',
      addSchoolPageLink: 'Add School Page Link',
      updateSchoolPageLink: 'Update School Page Link',
      updateVideo: 'Update Video',

      studentSearch: 'Student Search',
      advancedStudentSearch: 'Advanced Student Search',
      graduatedStudents: 'Graduated Students',
      archivedStudents: 'Archived Students',
      editCurrentStudents: 'Edit Current Students',
      manageStudentSubscription: 'Manage Student Subscription',
      exportStudents: 'Export Students',

      // System Defaults
      studentProfileConfigurations: 'Student Profile Configurations',
      studentProfileTextboxes: 'Student Profile Textboxes',
      editStudentProfileTextboxes: 'Edit Student Profile Textboxes',
      studentProgrammingNotes: 'Student Programming Notes',
      editStudentProgrammingNotes: 'Edit Student Programming Notes',
      meetingDescriptors: 'Meeting Descriptors',
      editMeetingDescriptors: 'Edit Meeting Descriptors',
      studentData: 'Student Data',
      editStudentData: 'Edit Student Data',
      editSchoolDefaults: 'Edit School Defaults',


      teamBoardConfigurations: 'Team Board Configurations',
      grades: 'Grades',
      editGrades: 'Edit Grades',
      studentSupportFocusAreas: 'Student Support Focus Areas',
      editStudentSupportFocusAreas: 'Edit Student Support Focus Areas',
      studentEntryLevels: 'Student Entry Levels',
      editStudentEntryLevels: 'Edit Student Entry Levels',
      studentSupportTiers: 'Student Support Tiers',
      editStudentSupportTiers: 'Edit Student Support Tiers',
      studentCardIcons: 'Student Card Icons',
      entryLevels: 'Entry Levels',
      editStudentCardIcons: 'Edit Student Card Icons',

      supportPlanConfigurations: 'Support Plan Configurations',
      supportTeamRole: 'Support Team Role',
      editSupportTeamRole: 'Edit Support Team Role',
      supportPlanInformation: 'Support Plan Information',
      editSupportPlanInformation: 'Edit Support Plan Information',

      transitionPlanning: 'Transition Planning',
      editTransitionPlanning: 'Edit Transition Planning',
      reviewDate: 'Review Date',
      editReviewDate: 'Edit Review Date',
      signatures: 'Signatures',
      generalConfigurations: 'General Configurations',

      // District
      districtHome: 'District Options',
      resources: 'Resources',

      // Meetings
      meetings: 'Meetings',
      [MeetingPanelTypes.boardsSchools]: 'District Team Boards - Schools',
      [MeetingPanelTypes.collaborativeSchools]: 'District Collaborative Team Meetings - Schools',
      [MeetingPanelTypes.schoolProfile]: 'School Profile',
      [MeetingPanelTypes.boardsStudentsIntegrated]: 'Integrated Team Board - Students',
      [MeetingPanelTypes.boardsStudentsDesegregated]: 'District Team Board - Students',
      [MeetingPanelTypes.collaborativeStudents]: 'District Collaborative Team Meetings - Students',
      addDistrictTeamBoardSchools: 'Add District Team Board',
      editDistrictTeamBoardSchools: 'Edit District Team Board',
      addDistrictTeamBoardStudentsIntegrated: 'Add Integrated Team Board For Students',
      editDistrictTeamBoardStudentsIntegrated: 'Edit Integrated Team Board For Students',
      addDistrictTeamBoardStudentsDesegregated: 'Add District Team Board For Students',
      editDistrictTeamBoardStudentsDesegregated: 'Edit District Team Board For Students',

      // School Profiles
      [SchoolProfilePanelsTypes.schoolMeetings]: 'School Meetings',
      [SchoolProfilePanelsTypes.schoolActions]: 'School Actions',
      [SchoolProfilePanelsTypes.schoolResources]: 'School Resources',

      // Actions
      [ActionPanelTypes.districtActions]: 'District Actions',
      [ActionPanelTypes.myActions]: 'My Actions',
      [ActionPanelTypes.studentActions]: 'Student Actions',

      // District -> Schools
      districtSchools: 'Schools',
      districtConfigurations: 'District Configurations',
      logo: 'Logo',
      districtMeetingDescriptions: 'District Meeting Descriptors',
      editDistrictMeetingDescriptions: 'Edit District Meeting Descriptors',
      schoolTextboxes: 'School Textboxes',
      editSchoolTextboxes: 'Edit School Textboxes',
      viewNotes: 'View Notes',
      startSchoolMeeting: 'Start School Meeting',
      editSchoolMeeting: 'Edit School Meeting',
      scheduleSchoolMeeting: 'Schedule School Meeting',
      addSchoolMeetingNote: 'Add School Meeting Note',
      editSchoolMeetingNote: 'Edit School Meeting Note',

      addEdit: 'Add/Edit',
      addStudent: 'Add New Student',
      students: 'Students',
      automaticAssignment: 'Automatic Assignment',
      manualAssignment: 'Manual Assignment',
      editGradeTeacher: 'Edit Grade/Teacher',

      staff: 'Staff',
      plans: 'Plans',

      // School
      schoolOptions: 'School Options',
      schoolConfigure: 'School Configure',
      schoolConfigs: 'School Configurations',

      // Boards
      [MeetingPanelTypes.boardsSchoolLevel]: 'Team Boards',
      [MeetingPanelTypes.collaborativeSchoolLevel]: 'Collaborative Team Meetings',
      addSchoolTeamBoard: 'Add Team Board',
      editSchoolTeamBoard: 'Edit Team Board',
      addCustomSchoolTeamBoard: 'Add Custom Team Board',
      editCustomSchoolTeamBoard: 'Edit Custom Team Board',

      // Supports
      supports: 'Supports',

      // Plans
      activatedIpp: 'Active Support Plans',
      deactivatedIpp: 'Deactivated Support Plans',
      bulkExportStudentSupportPlans: 'Bulk Export Student Support Plans',

      // Student Profile
      generalProfile: 'General Profile Configurations',
      supportPlan: 'Support Plan',
      ippProfile: 'Support Plan Home',
      supportTeam: 'Support Team',
      specializedAssessment: 'Specialized Assessment',
      goalPlanning: 'Goal Planning',

      // Student Profile
      levelsTiersIcons: 'Entry Levels, Tiers of Support, Student Card Icons',

      boardView: 'Board View',
      noSubGoalsAdded: 'FR There are no Sub-goals added',
      goalOverview: 'Goal Overview',
      actions: 'Actions',
    },
    dialogs: {
      accountSuspendedTitle: 'Account Suspended',
      accountSuspendedMessage: 'This account is currently suspended. Please contact wecollab@jigsawlearning.ca.',

      returnStudentToActiveRosterMessage: 'Are you sure you want to return this student(s) to the active roster?',
      deleteStudentsTitle: 'Delete Student(s)',
      deleteStudentMessage: 'Do you wish to permanently delete this student(s)?',

      resourceCreated: 'Resource Created',
      resourceUpdated: 'Resource Updated',
      resourceNotFound: 'Resource not found',

      addComment: 'Add Comment',
      editComment: 'Edit Comment',

      addSupport: 'Add New Support',
      updateSupport: 'Update Support',

      logoutTitle: 'Log Out',
      logoutMessage: 'Are you sure you want to log out?',

      deleteBoardCategoryTitle: 'Delete Team Board Category',
      deleteBoardCategoryMessage: 'Are you sure you do not want to delete this category from this board?',

      addAction: 'Add Action',
      updateAction: 'Update Action',

      nextSupportReviewDate: 'Next Support Review Date',
      meetingNotes: 'Meeting Notes',
    },
    panelsTexts: {
      addedBy: 'Added by',
      editedBy: 'Edited by',
    },
    resolvers: {
      districtNotFound: 'District not found',
      schoolNotFound: 'School not found',
      districtOrSchoolNotFound: 'District or School not found',
      invalidUrl: 'Invalid url',
      invalidId: 'You provided invalid id in url',
      districtSuspended: 'District you\'re trying to access is suspended',
      schoolSuspended: 'School account you\'re trying to access is suspended'
    },
    suspended: 'Suspended',
    activated: 'Activated',
    all: 'All',
    meeting: 'Meeting',

    emailCopyOfMeetingNotes: 'Email Copy Of Meeting Notes',
    noActionAtThisTime: 'No action at this time',
    notes: {
      schoolSupportPlanDeactivated: 'School Support Plan Module Deactivated',
      noSubGoalsAdded: 'There are no Sub-goals added',
      completedAction: 'Action completed, you can\'t make changes',
      abandonedAction: 'Action abandoned, you can\'t make changes',
    },
    headerNavigation: {
      userSupport: 'User Support',
      reportIssue: 'Report an Issue',
      shareFeedback: 'Share Feedback',
    }
  };
}
