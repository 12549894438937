import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum SupportTypes {
  accommodation = 'accommodation',
  strategy = 'strategy',
  intervention = 'intervention'
}

export function getSupportTypesDisplayValues(translationService?: TranslationService): {[key in SupportTypes]: string} {
  if (translationService) {
    return {
      [SupportTypes.accommodation]: translationService.translate(`studentSupportTypes.${SupportTypes.accommodation}`),
      [SupportTypes.strategy]: translationService.translate(`studentSupportTypes.${SupportTypes.strategy}`),
      [SupportTypes.intervention]: translationService.translate(`studentSupportTypes.${SupportTypes.intervention}`),
    };
  } else {
    return {
      [SupportTypes.accommodation]: 'Accommodation',
      [SupportTypes.strategy]: 'Strategy',
      [SupportTypes.intervention]: 'Intervention',
    };
  }
}
