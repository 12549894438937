import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum SchoolStatuses {
  account_suspended = 'account_suspended',
  payment_pending = 'payment_pending',
  good = 'good'
}

export type SchoolStatusesEnumMap = {
  [K in SchoolStatuses]: string;
};

export function getSchoolStatusDisplayValues(translationService?: TranslationService): SchoolStatusesEnumMap {
  if (translationService) {
    return {
      [SchoolStatuses.good]: translationService.translate(`schoolStatuses.${SchoolStatuses.good}`),
      [SchoolStatuses.payment_pending]: translationService.translate(`schoolStatuses.${SchoolStatuses.payment_pending}`),
      [SchoolStatuses.account_suspended]: translationService.translate(`schoolStatuses.${SchoolStatuses.account_suspended}`)
    };
  } else {
    return {
      [SchoolStatuses.good]: 'Active',
      [SchoolStatuses.payment_pending]: 'Pending',
      [SchoolStatuses.account_suspended]: 'Suspended'
    };
  }
}
