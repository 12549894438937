import { Component, OnInit } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { debounceTime, filter, takeWhile } from 'rxjs/operators';
import { pipe } from 'rxjs';

// services
import { HistoryService } from '@shared/services/history.service';
import { BaseColorService } from '@shared/services/base-color.service'
import { DistrictAndSchoolDataService } from '@shared/services/district-and-school-data.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { AuthService } from '@auth/services/auth.service';
import { ScrollPositionService } from '@shared/services/scroll-position.service';
import {exportTranslations} from "@shared/utils/export-translations";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public loadingLazyModule = false;
  public isLoadingDistrictOrSchoolLevel = false;
  public isUserSimulationLoading = false;

  constructor(
    private readonly permissionsService: PermissionsService,
    public readonly authService: AuthService,
    private readonly historyService: HistoryService,
    public readonly baseColorService: BaseColorService,
    private readonly scrollPositionService: ScrollPositionService,
    public readonly dataService: DistrictAndSchoolDataService,
    private readonly router: Router
  ) {
    this.historyService.startRecordingHistory();
  }

  ngOnInit(): void {
    this.permissionsService.permissionInitComplete$
      .pipe(takeWhile(completed => !!completed))
      .subscribe((completed: boolean) => {
        if (completed) {
          this.permissionsService.permissionInitComplete$.complete();
          this.subscribeForLazyLoading();
        }
      });

    this.permissionsService.userSimulationChangeInProgress$
      .subscribe(value => this.isUserSimulationLoading = value);

    this.dataService.update$
      .subscribe((action) => {
        this.isLoadingDistrictOrSchoolLevel = action.type === 'resolving';
      });
  }

  private subscribeForLazyLoading(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof RouteConfigLoadStart || event instanceof RouteConfigLoadEnd),
        pipe(debounceTime(100))
      )
      .subscribe(event => {

        // Show progress bar at top for lazy loading modules
        if (event instanceof RouteConfigLoadStart) {
          this.loadingLazyModule = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingLazyModule = false;
        }
      });
  }
}
