import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum Months {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December'
}

export type MonthsEnumMap = {
  [P in Months]: string;
};

export function getMonthDisplayValues(translationService?: TranslationService): MonthsEnumMap {
  if (translationService) {
    return {
      [Months.January]: translationService.translate(`months.${Months.January}`),
      [Months.February]: translationService.translate(`months.${Months.February}`),
      [Months.March]: translationService.translate(`months.${Months.March}`),
      [Months.April]: translationService.translate(`months.${Months.April}`),
      [Months.May]: translationService.translate(`months.${Months.May}`),
      [Months.June]: translationService.translate(`months.${Months.June}`),
      [Months.July]: translationService.translate(`months.${Months.July}`),
      [Months.August]: translationService.translate(`months.${Months.August}`),
      [Months.September]: translationService.translate(`months.${Months.September}`),
      [Months.October]: translationService.translate(`months.${Months.October}`),
      [Months.November]: translationService.translate(`months.${Months.November}`),
      [Months.December]: translationService.translate(`months.${Months.December}`)
    };
  } else {
    return {
      [Months.January]: Months.January,
      [Months.February]: Months.February,
      [Months.March]: Months.March,
      [Months.April]: Months.April,
      [Months.May]: Months.May,
      [Months.June]: Months.June,
      [Months.July]: Months.July,
      [Months.August]: Months.August,
      [Months.September]: Months.September,
      [Months.October]: Months.October,
      [Months.November]: Months.November,
      [Months.December]: Months.December
    };
  }
}
