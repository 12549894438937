import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';

// services
import { AppStateService } from '@shared/services/app-state.service';

// models
import { Workspaces } from '@shared/models/resource-types.model';

@Injectable({ providedIn: 'root' })
export class DistrictUserGuard implements CanLoad, CanActivate {
  constructor(
    private readonly router: Router,
    private readonly appStateService: AppStateService
  ) {
  }

  canLoad(): boolean {
    return this.isDistrictUser();
  }

  canActivate(): boolean {
    return this.isDistrictUser();
  }

  private isDistrictUser(): boolean {
    if (this.appStateService.currentUserPermissions.userType === Workspaces.ADMIN || this.appStateService.currentUserPermissions.userType === Workspaces.DISTRICT) {
      return true;
    }

    this.router.navigate(['/not-found']);
    return false;
  }
}
