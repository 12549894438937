import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

// services
import { HistoryService } from '@shared/services/history.service';
import { PageStateService } from '@shared/services/page-state.service';
import { TrackRequestsService } from '@shared/services/track-requests.service';

// helpers
import { getRouteFromUrl } from '@shared/utils/route-from-url.helper';

interface IRouteScrollCache {
  url: string;
  scrollPosition: [number, number];
}

@Injectable({ providedIn: 'root' })
export class ScrollPositionService {
  private scrollPosition: [number, number] = [0, 0];

  // used in Student Profile page so far (for general/ipp profile switch)
  private _doNotScrollToTopOnRouteChange = false;

  private previousRoutes: Array<{ url: string, scrollPosition: [number, number] }> = [];
  private currentRoute: IRouteScrollCache;

  private waitingForRequestsSub: Subscription;

  constructor(
    private readonly pageStateService: PageStateService,
    private readonly historyService: HistoryService,
    private readonly viewportScroller: ViewportScroller,
    private readonly trackRequestsService: TrackRequestsService,
    private readonly router: Router
  ) {
    // this.pageStateService.updatingPageState$.subscribe((value: boolean) => this.causedByUpdateStateChange = value);

    this.subscribeToNavigationEnd();

    this.subscribeToScrollEvents();
  }

  private get firstRoute(): { url: string, scrollPosition: [number, number] } {
    return this.previousRoutes.length === 2 ? this.previousRoutes[this.previousRoutes.length - 2] : null;
  }

  private subscribeToNavigationEnd(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map((e: NavigationEnd) => e.urlAfterRedirects)
      )
      .subscribe((urlAfterRedirects: string) => {
        // if (this.causedByUpdateStateChange) {
        //   this.causedByUpdateStateChange = false;
        //   return;
        // }

        // Same page: don't scroll (seems like page state update is handled by this as well)
        if (getRouteFromUrl(urlAfterRedirects) === this.currentRoute?.url) {
          return;
        }

        if (this._doNotScrollToTopOnRouteChange) {
          return;
        }

        if (!!this.currentRoute) {
          this.previousRoutes.push(this.currentRoute);
        }
        if (this.previousRoutes.length > 2) {
          this.previousRoutes.shift();
        }

        this.currentRoute = {
          url: getRouteFromUrl(urlAfterRedirects),
          scrollPosition: this.scrollPosition
        };

        if (this.currentRoute.url === this.firstRoute?.url) {
          // Previous page, restore scroll position
          if (!!this.currentRoute) {
            this.restoreScrollPosition();
          }
        } else {
          // New page
          this.viewportScroller.scrollToPosition([0, 0]);

          if (this.waitingForRequestsSub) {
            this.waitingForRequestsSub.unsubscribe();
          }
        }
      });

    // if (this.requestedURLs.size === 0) {
    //   // No request to wait for
    //   if (!this._doNotScrollToTopOnRouteChange) {
    //     this.viewportScroller.scrollToPosition([x, y]);
    //   }
    //   this.disableScroll = false;
    // } else {
    //   this.scrollSub = this.allRequestsCompleted$
    //     .subscribe(() => {
    //       // Wait for all requests in the page to complete so that scroll position will match
    //       if (!this._doNotScrollToTopOnRouteChange) {
    //         this.viewportScroller.scrollToPosition([x, y]);
    //       }
    //       this.disableScroll = false;
    //       this.scrollSub.unsubscribe();
    //     });
    // }
  }

  private restoreScrollPosition(): void {
    // setTimeout makes WaitForSamePageRequestsInterceptor work and set values for this.trackRequestsService.requestedURLs
    setTimeout(() => {
      if (this.trackRequestsService.requestedURLs.size === 0) {
        this.viewportScroller.scrollToPosition(this.firstRoute.scrollPosition);
        this.previousRoutes = [];
      } else {
        this.waitingForRequestsSub = this.trackRequestsService.allRequestsCompleted$
          .subscribe(() => {
            if (!!this.firstRoute) {
              this.viewportScroller.scrollToPosition(this.firstRoute.scrollPosition);
            }
            this.previousRoutes = [];

            if (this.waitingForRequestsSub) {
              this.waitingForRequestsSub.unsubscribe();
            }
          });
      }
    });
  }

  private subscribeToScrollEvents(): void {
    fromEvent(window, 'scroll')
      .pipe(
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (!!this.currentRoute) {
          this.currentRoute.scrollPosition = this.viewportScroller.getScrollPosition();
        }
      });

    fromEvent(window, 'keydown')
      .subscribe((event: KeyboardEvent) => {
        let isKeyUpOrKeyDown = false;

        if (event.key !== undefined) {
          isKeyUpOrKeyDown = event.key === 'ArrowUp' || event.key === 'ArrowDown';
        } else if (event.keyCode !== undefined) {
          isKeyUpOrKeyDown = event.keyCode === 38 || event.keyCode === 40;
        }

        if (!isKeyUpOrKeyDown) {
          return;
        }
      });
  }

  public disableScrollTopOnRouteChanges(): void {
    this._doNotScrollToTopOnRouteChange = true;
  }

  public enableScrollTopOnRouteChanges(): void {
    this._doNotScrollToTopOnRouteChange = false;
  }
}
