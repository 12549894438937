import { Injectable } from '@angular/core';

// material
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarConfig } from '@angular/material/snack-bar/snack-bar-config';

// components
import { SharedNotificationComponent } from '@shared/modules/notification-dialogs/components/shared-notification.component';

// models
import { NotificationTypes } from '@shared/modules/notification-dialogs/models/notification-types.model';
import { INotificationDialogData } from '@shared/modules/notification-dialogs/models/notification-dialog-data.model';

// configs
import { getNotificationClassesByType } from '@shared/modules/notification-dialogs/configs/notificaion-classes.config';

@Injectable()
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar
  ) {
  }

  public showNotification(type: NotificationTypes, message: string | string[], snackBarConfig?: MatSnackBarConfig) {
    const data: INotificationDialogData = {
      ...getNotificationClassesByType(type),
      type
    };

    if (Array.isArray(message)) {
      data.messages = message;
    } else {
      data.message = message;
    }

    let config: MatSnackBarConfig = snackBarConfig;

    if (!snackBarConfig) {
      config = {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      }
    } else {
      config.duration = +snackBarConfig.duration || 5000;
      config.verticalPosition = snackBarConfig.verticalPosition || 'top';
      config.horizontalPosition = snackBarConfig.horizontalPosition || 'right';
    }

    return this.snackBar.openFromComponent(SharedNotificationComponent, {
      ...config,
      data
    });

  }
}
