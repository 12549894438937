import { HttpParams } from '@angular/common/http';
import { IQueryParam } from '@shared/models/query-param.model';

export function anyToHttpParams(obj: IQueryParam): HttpParams {
  let param = new HttpParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const arrayKey = `${key}[]`;

      // Array is sent to API like this: propertyName[]=value1&propertyName[]=value2&propertyName[]=value3
      // If array is empty => remove it from query params
      // If array item is null or undefined => remove it from array
      if (obj[key] && Array.isArray(obj[key])) {
        const arrayNotNullValues = (obj[key] as Array<any>).filter(val => val !== null && val !== undefined);
        if (!arrayNotNullValues.length) {
          return param; // TODO maybe continue, not return?
        }

        param = param.set(arrayKey, obj[key][0]);

        for (let i = 1; i < arrayNotNullValues.length; i++) {
          param = param.append(arrayKey, arrayNotNullValues[i]);
        }
      } else if (typeof obj[key] === 'boolean' || !!obj[key]) {
        // For boolean properties set boolean value (true/false), for other types: truthy values
        param = param.set(key, obj[key] as string);
      }
    }
  }

  return param;
}
