import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum UserRoles {
  Super_Admin = 'super',
  District_Admin = 'district_admin',
  District_Leader = 'district_leader',
  District_Staff = 'district_staff',
  School_Admin = 'school_admin',
  School_Leader = 'school_leader',
  School_Teacher = 'school_teacher',
  School_Staff = 'school_staff',
}

export type UserRolesEnumMap = {
  [P in UserRoles]?: string;
};

export function getUserRolesDisplayValues(translationService?: TranslationService): UserRolesEnumMap {
  if (translationService) {
    return {
      [UserRoles.District_Leader]: translationService.translate(`userTypes.${UserRoles.District_Leader}`),
      [UserRoles.District_Staff]: translationService.translate(`userTypes.${UserRoles.District_Staff}`),
      [UserRoles.District_Admin]: translationService.translate(`userTypes.${UserRoles.District_Admin}`),
      [UserRoles.School_Teacher]: translationService.translate(`userTypes.${UserRoles.School_Teacher}`),
      [UserRoles.School_Leader]: translationService.translate(`userTypes.${UserRoles.School_Leader}`),
      [UserRoles.School_Staff]: translationService.translate(`userTypes.${UserRoles.School_Staff}`),
      [UserRoles.School_Admin]: translationService.translate(`userTypes.${UserRoles.School_Admin}`)
    };
  } else {
    return {
      [UserRoles.District_Leader]: 'District Leader',
      [UserRoles.District_Staff]: 'District Staff',
      [UserRoles.District_Admin]: 'District Admin',
      [UserRoles.School_Teacher]: 'School Teacher',
      [UserRoles.School_Leader]: 'School Leader',
      [UserRoles.School_Staff]: 'School Staff',
      [UserRoles.School_Admin]: 'School Admin'
    };
  }
}
