import { Component } from '@angular/core';

@Component({
  template: `
    <p style="text-align: center; width: 100%; line-height: 100%">
      Page not found
    </p>
  `
})
export class NotFoundComponent {
}
