import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStateService } from '@shared/services/app-state.service';

@Injectable()
export class SimulateUserInterceptor implements HttpInterceptor {
  constructor(
    private readonly appStateService: AppStateService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addUserIdToHeaders(req));
  }

  private addUserIdToHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const userID = this.appStateService.getSimulationUserID;
    if (!userID) {
      return request;
    }

    return request.clone({ setHeaders: { User: `${userID}` } });
  }
}
