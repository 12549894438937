// Not Finished
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';

// services
import { AppStateService } from '@shared/services/app-state.service';
import { NotificationService } from "@shared/modules/notification-dialogs/services/notification.service";

// models
import { Workspaces } from '@shared/models/resource-types.model';
import { NotificationTypes } from "@shared/modules/notification-dialogs/models/notification-types.model";

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanLoad, CanActivate {

  constructor(
    private readonly router: Router,
    private readonly appStateService: AppStateService,
    private readonly notificationService: NotificationService,
  ) {
  }

  canLoad(): boolean {
    return this.check();
  }

  canActivate(): boolean {
    return this.check();
  }

  private check(): boolean {
    if (this.appStateService.currentUserPermissions.userType === Workspaces.ADMIN) {
      return true;
    }

    if (this.appStateService.getSimulationUserID) {
      this.notificationService.showNotification(
          NotificationTypes.WARNING,
          'In order to go back to Super Admin view please tap on the Stop Simulate button.'
      );

      return false;
    }

    this.router.navigate(['/not-found']);
    return false;
  }
}
