import { APP_INITIALIZER } from '@angular/core';

// services
import { PermissionsService } from '@shared/services/permissions.service';

function initUserPermissions(permissionsService: PermissionsService) {
  return () => permissionsService.initPermissions();
}

export function appInitializerInterceptor() {
  return {
    provide: APP_INITIALIZER,
    useFactory: initUserPermissions,
    deps: [PermissionsService],
    multi: true
  }
}
