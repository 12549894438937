import { Injectable } from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';

// services
import { PageStateService } from '@shared/services/page-state.service';

// models
import { IHistoryItem } from '@shared/models/history-item.model';

// helpers
import { getRouteFromUrl } from '@shared/utils/route-from-url.helper';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class HistoryService {
  private visitedRoutes: Array<IHistoryItem> = [];
  constructor(
    private readonly router: Router,
    private readonly pageStateService: PageStateService,
    private readonly localStorageService: LocalStorageService
  ) {
  }

  public startRecordingHistory(): void {
    this.router.events
      .pipe(
        tap((event: RouterEvent) => {

          //when site is reloading ,read VisitedRoutes from local storage
          if (event instanceof NavigationStart && !this.router.navigated) {
            this.visitedRoutes = this.localStorageService.getVisitedRoutes() || [];
          }
        }),
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url)
      )
      .subscribe(
        (url: string): void => {
          // Get url without query params
          const urlWithoutQueryParams = url.split('?')[0];

         // check if income url is the same level of 
          const urlLength = urlWithoutQueryParams.split('/').length
          if (this.visitedRoutes.length > 1) {
            this.visitedRoutes.forEach((item, index) => {
              if (item.length === urlLength) {
                this.visitedRoutes = this.visitedRoutes.slice(0, index);
                return;
              }
            })
          }
          // Get page name for rendering in breadcrumbs template
          const pageName = this.pageNamesList[urlWithoutQueryParams.replace(/[0-9]+(\/$)?/g, ':param')];


          // Remove duplicate if this route was visited before
          const existingItemIndex = this.visitedRoutes.findIndex(item => item.pageName === pageName);
          if (existingItemIndex !== -1) {
            this.visitedRoutes.splice(existingItemIndex, 1);
          }

          // Query params
          const queryParams = this.pageStateService.getRouterParamsFromPageStates();

          //Delete 1st item if we already have 3 items in history
          if (this.visitedRoutes.length === 3) {
            this.visitedRoutes.splice(0, 1);
          }

          // Add new route to history
          this.visitedRoutes.push({
            url: urlWithoutQueryParams,
            pageName,
            queryParams,
            length: urlLength
          });
          // save in local storage 
          this.localStorageService.setVisitedRoutes(this.visitedRoutes);
        }

      );

  }

  public getHistory(): Array<IHistoryItem> {
    return this.visitedRoutes;
  }

  public getPageName(url: string): string {
    return this.pageNamesList[getRouteFromUrl(url)];
  }

  private readonly pageNamesList = {
    // Admin Pages
    '/admin': 'pageNames.systemStatisticsOverview',
    '/admin/edit-school/:param/:param': 'pageNames.schoolAccount',
    '/admin/edit-district/:param': 'pageNames.districtAccount',
    '/admin/add-district': 'pageNames.districtAccount',

    // defaults
    '/admin/system-defaults': 'pageNames.defaults',
    '/admin/system-defaults/student-support-focus-areas': 'pageNames.editStudentSupportFocusAreas',
    '/admin/system-defaults/student-entry-levels': 'pageNames.studentEntryLevels',
    '/admin/system-defaults/student-support-tiers': 'pageNames.studentSupportTiers',
    '/admin/system-defaults/student-card-icons': 'pageNames.studentCardIcons',
    '/admin/system-defaults/grades': 'pageNames.grades',
    '/admin/system-defaults/support-links': 'pageNames.supportLinks',
    '/admin/system-defaults/student-profile-textboxes': 'pageNames.studentProfileTextboxes',
    '/admin/system-defaults/meeting-descriptors': 'pageNames.meetingDescriptors',
    '/admin/system-defaults/student-programming-notes': 'pageNames.studentProgrammingNotes',
    '/admin/system-defaults/support-team-role': 'pageNames.supportTeamRole',
    '/admin/system-defaults/support-plan-information': 'pageNames.supportPlanInformation',
    '/admin/system-users': 'pageNames.searchUsers',
    '/admin/system-users/account-credentials/:param': 'pageNames.accountCredentials',
    '/admin/sync-data': 'pageNames.connectedDistricts',
    '/admin/edit-user-emails': 'pageNames.editUserEmails',

    // Supports
    '/admin/student-supports': 'pageNames.studentSupports',
    '/admin/student-supports/student-support-network': 'pageNames.studentSupportNetwork',
    '/admin/student-supports/student-support-network/view/:param': 'pageNames.viewStudentSupport',
    '/admin/student-supports/student-support-network/view-approved/:param': 'pageNames.viewStudentSupport',
    '/admin/student-supports/edit-approved-student-support/:param': 'pageNames.editStudentSupport',
    '/admin/student-supports/approve-support/:param': 'pageNames.editStudentSupport',
    '/admin/student-supports/edit-student-support/:param': 'pageNames.editStudentSupport',
    '/admin/student-supports/edit-new-student-support/:param': 'pageNames.editNewStudentSupport',

    // Home Page Updates
    '/admin/home-page-updates': 'pageNames.home',
    '/admin/home-page-updates/add-slider-data': 'pageNames.addSliderData',
    '/admin/home-page-updates/edit-slider-data/:param': 'pageNames.updateSliderData',
    '/admin/home-page-updates/add-testimonials-data': 'pageNames.addTestimonialData',
    '/admin/home-page-updates/edit-testimonials-data/:param': 'pageNames.updateTestimonialData',
    '/admin/home-page-updates/add-school-home-page-link': 'pageNames.addSchoolPageLink',
    '/admin/home-page-updates/edit-school-home-page-link/:param': 'pageNames.updateSchoolPageLink',
    '/admin/home-page-updates/edit-home-page-video/:param': 'pageNames.updateVideo',

    // District Pages
    '/district/:param': 'pageNames.districtHome',
    '/district/:param/advanced-student-search': 'pageNames.advancedStudentSearch',
    '/district/:param/graduated-students': 'pageNames.graduatedStudents',
    '/district/:param/archived-students': 'pageNames.archivedStudents',
    '/district/:param/schools': 'pageNames.districtSchools',
    '/district/:param/schools/defaults': 'pageNames.editSchoolDefaults',
    '/district/:param/schools/defaults/student-profile-textboxes': 'pageNames.studentProfileTextboxes',
    '/district/:param/schools/defaults/student-programming-notes': 'pageNames.studentProgrammingNotes',
    '/district/:param/schools/defaults/meeting-descriptors': 'pageNames.meetingDescriptors',
    '/district/:param/schools/defaults/student-data': 'pageNames.studentData',
    '/district/:param/schools/defaults/grades': 'pageNames.grades',
    '/district/:param/schools/defaults/student-support-focus-areas': 'pageNames.editStudentSupportFocusAreas',
    '/district/:param/schools/defaults/student-entry-levels': 'pageNames.studentEntryLevels',
    '/district/:param/schools/defaults/student-support-tiers': 'pageNames.studentSupportTiers',
    '/district/:param/schools/defaults/student-card-icons': 'pageNames.studentCardIcons',
    '/district/:param/schools/:param/school-profile': 'pageNames.schoolProfile',
    '/district/:param/schools/:param/school-profile/start-school-meeting': 'pageNames.startSchoolMeeting',
    '/district/:param/schools/:param/school-profile/edit-school-meeting/:param': 'pageNames.editSchoolMeeting',
    '/district/:param/schools/:param/school-profile/schedule-school-meeting': 'pageNames.scheduleSchoolMeeting',
    '/district/:param/schools/:param/school-profile/school-meeting-note/:param/:param': 'pageNames.addSchoolMeetingNote',
    '/district/:param/schools/:param/school-profile/school-meeting-note/:param/:param/:param': 'pageNames.editSchoolMeetingNote',
    '/district/:param/resources': 'pageNames.resources',
    '/district/:param/meetings': 'pageNames.meetings',
    '/district/:param/meetings/district-meeting-descriptors': 'pageNames.editDistrictMeetingDescriptions',
    '/district/:param/meetings/school-textboxes': 'pageNames.editSchoolTextboxes',
    '/district/:param/meetings/logo': 'pageNames.logo',
    '/district/:param/meetings/add-district-team-board-schools': 'pageNames.addDistrictTeamBoardSchools',
    '/district/:param/meetings/edit-district-team-board-schools/:param': 'pageNames.editDistrictTeamBoardSchools',
    '/district/:param/meetings/add-district-team-board-students': 'pageNames.addDistrictTeamBoardStudentsDesegregated',
    '/district/:param/meetings/edit-district-team-board-students/:param': 'pageNames.editDistrictTeamBoardStudentsDesegregated',
    '/district/:param/meetings/add-integrated-team-board-students': 'pageNames.addDistrictTeamBoardStudentsIntegrated',
    '/district/:param/meetings/edit-integrated-team-board-students/:param': 'pageNames.editDistrictTeamBoardStudentsIntegrated',
    '/district/:param/board/:param': 'pageNames.boardView',
    '/district/:param/board/school-board/:param': 'pageNames.boardView',
    '/district/:param/staff': 'pageNames.staff',
    '/district/:param/plans': 'pageNames.plans',
    '/district/:param/plans/configurations': 'pageNames.plansConfigurations',
    '/district/:param/plans/bulk-export': 'pageNames.bulkExportStudentSupportPlans',
    '/district/:param/actions': 'pageNames.actions',

    // District Supports
    '/district/:param/supports': 'pageNames.supports',
    '/district/:param/supports/student-support-network': 'pageNames.studentSupportNetwork',
    '/district/:param/supports/student-support-network/view/:param': 'pageNames.viewStudentSupport',
    '/district/:param/supports/student-support-network/view-for-import/:param': 'pageNames.importStudentSupport',
    '/district/:param/supports/add-student-support-network/:param': 'pageNames.addStudentSupport',
    '/district/:param/supports/import-student-support-network/:param': 'pageNames.importStudentSupport',
    '/district/:param/supports/edit-student-support-network/:param': 'pageNames.editStudentSupport',

    // School pages
    '/school/:param/:param': 'pageNames.schoolOptions',
    '/school/:param/:param/configure': 'pageNames.schoolConfigure',
    '/school/:param/:param/add-edit': 'pageNames.addEdit',
    '/school/:param/:param/add-edit/add-student': 'pageNames.addStudent',
    '/school/:param/:param/add-edit/automatic-assignment': 'pageNames.automaticAssignment',
    '/school/:param/:param/add-edit/manual-assignment': 'pageNames.manualAssignment',
    '/school/:param/:param/add-edit/edit-grade-teacher': 'pageNames.editGradeTeacher',
    '/school/:param/:param/students': 'pageNames.students',
    '/school/:param/:param/students/automatic-assignment': 'pageNames.automaticAssignment',
    '/school/:param/:param/students/manual-assignment': 'pageNames.manualAssignment',
    '/school/:param/:param/advanced-student-search': 'pageNames.advancedStudentSearch',
    '/school/:param/:param/graduated-students': 'pageNames.graduatedStudents',
    '/school/:param/:param/archived-students': 'pageNames.archivedStudents',
    '/school/:param/:param/add-edit/edit-current-students': 'pageNames.editCurrentStudents',
    '/school/:param/:param/students/manage-subscription': 'pageNames.manageStudentSubscription',
    '/school/:param/:param/add-edit/export-students': 'pageNames.exportStudents',

    // School Configure (Defaults)
    '/school/:param/:param/configure/student-profile-textboxes': 'pageNames.studentProfileTextboxes',
    '/school/:param/:param/configure/student-programming-notes': 'pageNames.studentProgrammingNotes',
    '/school/:param/:param/configure/meeting-descriptors': 'pageNames.meetingDescriptors',
    '/school/:param/:param/configure/student-data': 'pageNames.studentData',
    '/school/:param/:param/configure/student-support-focus-areas': 'pageNames.editStudentSupportFocusAreas',
    '/school/:param/:param/configure/student-entry-levels': 'pageNames.studentEntryLevels',
    '/school/:param/:param/configure/student-support-tiers': 'pageNames.studentSupportTiers',
    '/school/:param/:param/configure/student-card-icons': 'pageNames.studentCardIcons',
    '/school/:param/:param/configure/logo': 'pageNames.logo',
    '/school/:param/:param/plans': 'pageNames.plans',
    '/school/:param/:param/plans/configurations': 'pageNames.plansConfigurations',
    '/school/:param/:param/plans/bulk-export': 'pageNames.bulkExportStudentSupportPlans',

    // School boards
    '/school/:param/:param/boards': 'pageNames.boards',
    '/school/:param/:param/boards/add-team-board': 'pageNames.addSchoolTeamBoard',
    '/school/:param/:param/boards/edit-team-board/:param': 'pageNames.editSchoolTeamBoard',
    '/school/:param/:param/boards/add-custom-team-board': 'pageNames.addCustomSchoolTeamBoard',
    '/school/:param/:param/boards/edit-custom-team-board/:param': 'pageNames.editCustomSchoolTeamBoard',
    '/school/:param/:param/board/:param': 'pageNames.boardView',

    // School Supports
    '/school/:param/:param/supports': 'pageNames.supports',
    '/school/:param/:param/supports/student-support-network': 'pageNames.studentSupportNetwork',
    '/school/:param/:param/supports/student-support-network/view/:param': 'pageNames.viewStudentSupport',
    '/school/:param/:param/supports/student-support-network/view-for-import/:param': 'pageNames.importStudentSupport',
    '/school/:param/:param/supports/add-student-support-network/:param': 'pageNames.addStudentSupport',
    '/school/:param/:param/supports/import-student-support-network/:param': 'pageNames.importStudentSupport',
    '/school/:param/:param/supports/edit-student-support-network/:param': 'pageNames.editStudentSupport',
    '/school/:param/:param/supports/view-support-for-approval-by-leader/:param': 'pageNames.editStudentSupport',

    // Student Profile
    '/school/:param/:param/students/profile/:param': 'pageNames.generalProfile',
    '/school/:param/:param/students/profile/:param/support-plan': 'pageNames.ippProfile',
    '/school/:param/:param/students/profile/:param/support-plan/home': 'pageNames.ippProfile',
    '/school/:param/:param/students/profile/:param/support-plan/specialized-assessment': 'pageNames.specializedAssessment',
    '/school/:param/:param/students/profile/:param/support-plan/goal-planning': 'pageNames.goalPlanning',
    '/school/:param/:param/students/profile/:param/support-plan/transition-planning': 'pageNames.transitionPlanning',
  };
}
