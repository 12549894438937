import { HTTP_INTERCEPTORS } from '@angular/common/http';

// interceptors
import { TokenInterceptor } from './token.interceptor';
import { WaitForSamePageRequestsInterceptor } from '@shared/interceptors/wait-for-same-page-requests.interceptor';
import { SimulateUserInterceptor } from '@shared/interceptors/simulate-user.interceptor';
import { appInitializerInterceptor } from '@shared/interceptors/app-initializer.interceptor';

export const interceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: WaitForSamePageRequestsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SimulateUserInterceptor, multi: true },
  appInitializerInterceptor()
];
