import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum SupportPlanPermissions {
  NO_SELECT = 'no_select',
  IPP_COORDINATOR = 'ipp_cordinator',
  IPP_ADMIN = 'ipp_admin',
  IPP_TEACHER = 'ipp_teacher'
}

export type SupportPlanPermissionsEnumMap = {
  [P in SupportPlanPermissions]: string;
};

export function getSupportPlanPermissionsDisplayValues(translationService?: TranslationService): SupportPlanPermissionsEnumMap {
  if (translationService) {
    return {
      [SupportPlanPermissions.NO_SELECT]: translationService.translate(`supportPlanPermissions.${SupportPlanPermissions.NO_SELECT}`),
      [SupportPlanPermissions.IPP_COORDINATOR]: translationService.translate(`supportPlanPermissions.${SupportPlanPermissions.IPP_COORDINATOR}`),
      [SupportPlanPermissions.IPP_ADMIN]: translationService.translate(`supportPlanPermissions.${SupportPlanPermissions.IPP_ADMIN}`),
      [SupportPlanPermissions.IPP_TEACHER]: translationService.translate(`supportPlanPermissions.${SupportPlanPermissions.IPP_TEACHER}`),
    };
  } else {
    return {
      [SupportPlanPermissions.NO_SELECT]: 'Not Set',
      [SupportPlanPermissions.IPP_COORDINATOR]: 'Support Plan Coordinator',
      [SupportPlanPermissions.IPP_ADMIN]: 'Support Plan Admin',
      [SupportPlanPermissions.IPP_TEACHER]: 'Support Plan Teacher',
    };
  }
}
