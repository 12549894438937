// ng
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

// touchpad
import 'hammerjs';

// modules
import { NotificationDialogsModule } from '@shared/modules/notification-dialogs/notification-dialogs.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// components
import { AppComponent } from './app.component';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';

// guards
import { AdminGuard } from '@shared/guards/admin.guard';
import { AuthGuard } from '@shared/guards/auth.guard';
import { NoAuthGuard } from '@shared/guards/no-auth.guard';

// interceptors
import { interceptors } from '@shared/interceptors/index.interceptor';

// directives
import {InactivityCheckDirective} from "@shared/directives/inactivity-check.directive";


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    InactivityCheckDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NotificationDialogsModule.forRoot(),
    MatProgressBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    AdminGuard,
    AuthGuard,
    NoAuthGuard,
    interceptors
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
