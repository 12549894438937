// models
import { Workspaces } from '@shared/models/resource-types.model';
import { AdminRoutes, DistrictRoutes, SchoolRoutes } from '@shared/models/routes-config.model';
import { EditSystemDefaultRoutes } from "@shared-pages/system-defaults/models/edit-page-routes.model";
import { EditMeetingsRoutes } from "@district/modules/meetings/models/edit-page-routes.model";

export function getDistrictRoute(resourceId: number, routes: DistrictRoutes | EditSystemDefaultRoutes | EditMeetingsRoutes | Array<DistrictRoutes | EditSystemDefaultRoutes | EditMeetingsRoutes>): string {
  return getRoutes(`/${Workspaces.DISTRICT}/${resourceId}/`, routes);
}

export function getSchoolRoute(districtId: number, schoolId: number, routes: SchoolRoutes | EditSystemDefaultRoutes | Array<SchoolRoutes | EditSystemDefaultRoutes>): string {
  // One specific case for redirect after login (District/School level users)
  if (!Array.isArray(routes) && routes === SchoolRoutes.selectSchool) {
    return `/${Workspaces.SCHOOL}/${districtId}/${routes}`;
  }

  return getRoutes(`/${Workspaces.SCHOOL}/${districtId}/${schoolId}/`, routes);
}

export function getAdminRoute(routes: AdminRoutes | EditSystemDefaultRoutes | Array<AdminRoutes | EditSystemDefaultRoutes>): string {
  return getRoutes('/admin/', routes);
}

function getRoutes(mainRoute: string, routes: any | any[]): string {
  return Array.isArray(routes) ? `${mainRoute}${routes.join('/')}` : `${mainRoute}${routes}`.replace('//', '/');
}
