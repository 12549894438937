// models
import { Languages } from '@shared/modules/translations/models/languages.enum';

// translation configs
import { getFrenchTranslations } from '@shared/modules/translations/configs/french-translations.config';
import { getEnglishTranslations } from '@shared/modules/translations/configs/english-translations.config';

export function getLanguageTranslations(language: Languages): any {
  const languageConfigs = {
    [Languages.EN]: getEnglishTranslations,
    [Languages.FR]: getFrenchTranslations
  };

  return languageConfigs[language]();
}
