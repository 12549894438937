import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// material
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

// components
import { SharedNotificationComponent } from '@shared/modules/notification-dialogs/components/shared-notification.component';

// services
import { NotificationService } from '@shared/modules/notification-dialogs/services/notification.service';

@NgModule({
  declarations: [
    SharedNotificationComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule
  ]
})
export class NotificationDialogsModule {
  static forRoot(): ModuleWithProviders<NotificationDialogsModule> {
    return {
      ngModule: NotificationDialogsModule,
      providers: [
        NotificationService
      ]
    };
  }
}
