import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// models
import { IConfirmActionDialogData } from '@shared/components/dialogs/confirm-action/models/confirm-action-dialog-data.model';

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html'
})
export class ConfirmActionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmActionDialogData
  ) {
  }

  ngOnInit(): void {
    this.data.submitBtnClass = this.data.submitBtnClass || 'btn-theme';
    this.data.submitBtnText = this.data.submitBtnText || 'Yes';
    this.data.cancelBtnText = this.data.cancelBtnText || 'Cancel';
    this.data.showCancelBtn = 'showCancelBtn' in this.data ? this.data.showCancelBtn : true;
  }

  public close(result: boolean): void {
    this.dialogRef.close(result);
  }
}
