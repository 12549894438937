// Revue Required
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IExpansionPanelOCServiceEvent } from '@shared/models/expansion-panel-oc-service-event.model';
import { IList } from '@shared/models/simple.model';

@Injectable({ providedIn: 'root' })
export class ExpansionPanelOCService {

  private readonly states: IList<boolean> = Object.create(null);
  private readonly controllers: IList<string[]> = Object.create(null);

  private readonly stateStream: Subject<IExpansionPanelOCServiceEvent> = new Subject();
  private readonly controllerStream: Subject<IExpansionPanelOCServiceEvent> = new Subject();

  // Used in Student IPP Profile -> Team Members so far
  private readonly singlePanelStateStream: Subject<IExpansionPanelOCServiceEvent> = new Subject();

  // registration panel and controller
  public registerPanel(name: string): boolean {
    if (!(name in this.states)) this.states[name] = false;
    return this.states[name];
  }

  public registerController(name: string, controlledPanels: string[], forceRefresh = false): boolean {
    if (forceRefresh) this.controllers[name] = controlledPanels;
    else if (!(name in this.controllers)) this.controllers[name] = controlledPanels;
    return this.isAllOpen(name);
  }

  // get panel and controller stream
  public getPanelStateStream(name: string): Subject<IExpansionPanelOCServiceEvent> {
    return this.stateStream;
  }

  public getControllerStateStream(name: string): Subject<IExpansionPanelOCServiceEvent> {
    return this.controllerStream;
  }

  public getSinglePanelStateStream(name: string): Subject<IExpansionPanelOCServiceEvent> {
    return this.singlePanelStateStream;
  }

  // is all open in this controller group 
  public isAllOpen(name: string): boolean {
    for (let i = 0; i < this.controllers[name].length; i++) {
      if (!this.states[this.controllers[name][i]]) return false;
    }

    return true;
  }

  // detect changes whose occurred after the change this panel state
  public detectChanges(changedPanelName: string): void {
    const changedController = this.getControllerByPanelName(changedPanelName);

    const controllerState = this.isAllOpen(changedController);

    this.controllerStream.next({ name: changedController, state: controllerState });
  }

  // get controller whose contain this panel 
  private getControllerByPanelName(name: string): string | null {
    for (let key in this.controllers) {
      const i = this.controllers[key].find(elem => elem === name);
      if (i) return key;
    }

    return null;
  }

  // change all panels state to false
  public clearAll(): void {
    for (let key in this.states) {
      this.states[key] = false;
    }
  }

  // set panel state
  public setState(name: string, state: boolean): void {
    this.states[name] = state;
    this.detectChanges(name);
    this.singlePanelStateStream.next({ name, state });
  }

  // open/close all panels in this controller group
  public openAll(name: string): void {
    for (let i = 0; i < this.controllers[name].length; i++) {
      this.states[this.controllers[name][i]] = true;
      this.stateStream.next({ name: this.controllers[name][i], state: this.states[this.controllers[name][i]] });
    }
  }

  public closeAll(name: string): void {
    for (let i = 0; i < this.controllers[name].length; i++) {
      this.states[this.controllers[name][i]] = false;
      this.stateStream.next({ name: this.controllers[name][i], state: this.states[this.controllers[name][i]] });
    }
  }

}
