import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum IppTableColumns {
  STUDENT_NAME = 'STUDENT_NAME',
  TEMPLATE = 'TEMPLATE',
  SCHOOL = 'SCHOOL',
  TEACHER = 'TEACHER',
  LAST_REVISION = 'LAST_REVISION',
  SIGNATURES = 'SIGNATURES',
  DEACTIVATION_DATE = 'DEACTIVATION_DATE',
  ACTIONS = 'ACTIONS',
}

export function getColumnToQueryParamMap(): { [key in IppTableColumns]: string } {
  return {
    [IppTableColumns.STUDENT_NAME]: 'student_name',
    [IppTableColumns.TEMPLATE]: 'template',
    [IppTableColumns.SCHOOL]: 'school',
    [IppTableColumns.TEACHER]: 'teacher',
    [IppTableColumns.LAST_REVISION]: 'last_revision',
    [IppTableColumns.SIGNATURES]: 'signature', // TODO: update after API implements
    [IppTableColumns.DEACTIVATION_DATE]: 'deactivation_date',
    [IppTableColumns.ACTIONS]: 'action',
  };
}

export function getIppTableColumnDisplayValues(translationService: TranslationService): { [key in IppTableColumns]: string } {
  return {
    [IppTableColumns.STUDENT_NAME]: translationService.translate(`ippTableColumns.${IppTableColumns.STUDENT_NAME}`),
    [IppTableColumns.TEMPLATE]: translationService.translate(`ippTableColumns.${IppTableColumns.TEMPLATE}`),
    [IppTableColumns.SCHOOL]: translationService.translate(`ippTableColumns.${IppTableColumns.SCHOOL}`),
    [IppTableColumns.TEACHER]: translationService.translate(`ippTableColumns.${IppTableColumns.TEACHER}`),
    [IppTableColumns.LAST_REVISION]: translationService.translate(`ippTableColumns.${IppTableColumns.LAST_REVISION}`),
    [IppTableColumns.SIGNATURES]: translationService.translate(`ippTableColumns.${IppTableColumns.SIGNATURES}`),
    [IppTableColumns.DEACTIVATION_DATE]: translationService.translate(`ippTableColumns.${IppTableColumns.DEACTIVATION_DATE}`),
    [IppTableColumns.ACTIONS]: '',
  };
}
