// Not Finished
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
// services
import { AuthService } from '@auth/services/auth.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { UsersService } from '@shared/services/users.service';
import { CurrentUserModel } from '@shared/models/current-user.model';
// models
import { AdminRoutes, DistrictRoutes, SchoolRoutes } from '@shared/models/routes-config.model';
// helpers
import { getAdminRoute, getDistrictRoute, getSchoolRoute } from '@shared/utils/route-config.helper';
import { AppStateService } from '@shared/services/app-state.service';
import { Workspaces } from '@shared/models/resource-types.model';

@Injectable({ providedIn: 'root' })
export class NoAuthGuard implements CanLoad, CanActivate {

  constructor(
    private readonly usersService: UsersService,
    private readonly permissionsService: PermissionsService,
    private readonly authService: AuthService,
    private readonly appStateService: AppStateService,
    private readonly router: Router,
  ) {
  }

  canLoad(): Observable<boolean> {
    return this.isNotAuthenticated();
  }

  canActivate(): Observable<boolean> {
    return this.isNotAuthenticated();
  }

  private isNotAuthenticated(): Observable<boolean> {
    if (!this.authService.isAuthenticated) {
      return of(true);
    }

    if (!!this.appStateService.currentUser && !!this.appStateService.currentUserPermissions) {
      this.router.navigate([this.getRedirectUrl(this.appStateService.currentUserPermissions.userType)]);
    }

    return this.authService.getCurrentUser()
      .pipe(
        take(1),
        map((response: CurrentUserModel) => {
          let workspace: Workspaces;

          if (response.is_super) {
            workspace = Workspaces.ADMIN;
          } else if (response.district_level) {
            workspace = Workspaces.DISTRICT;
          } else {
            workspace = Workspaces.SCHOOL;
          }

          this.router.navigate([this.getRedirectUrl(workspace)]);
          return false;
        })
      );
  }

  private getRedirectUrl(workspace: Workspaces): string {
    switch (workspace) {
      case Workspaces.ADMIN:
        return getAdminRoute(AdminRoutes.home);
      case Workspaces.DISTRICT:
        return getDistrictRoute(this.appStateService.currentUser.district.id, DistrictRoutes.home);
      case Workspaces.SCHOOL:
        return getSchoolRoute(this.appStateService.currentUser.district.id, null, SchoolRoutes.selectSchool);
      default:
        return '';
    }
  }
}
