import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

// material
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

// models
import { INotificationDialogData } from '@shared/modules/notification-dialogs/models/notification-dialog-data.model';
import { NotificationTypes } from '@shared/modules/notification-dialogs/models/notification-types.model';

@Component({
  template: `
    <div class="notification" [ngClass]="data.containerClass">
      <button *ngIf="data.type === NOTIFICATION_TYPES.ERROR_WITH_CLOSE" mat-icon-button type="button" class="close-btn btn-icon btn-gray sm">
          <i class="ico-close"></i>
      </button>

      <span class="notification-icon-wrap">
        <i class="notification-icon" [ngClass]="data.iconClass"></i>
      </span>
      <div class="notification-body">
        <p *ngIf="data.message">{{data.message}}</p>
          
        <ng-container *ngIf="data.messages">
            <p>
              <ng-container *ngFor="let msg of data.messages">
                  <span>{{msg}}</span>
                  <br/>
              </ng-container>
            </p>
        </ng-container>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedNotificationComponent {

  public NOTIFICATION_TYPES = NotificationTypes;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: INotificationDialogData) {
  }
}
