import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum DataSourceDataTypes {
  month = 'month',
  label = 'label'
}

export type ResourceDataTypesEnumMap = {
  [P in DataSourceDataTypes]: string;
};

export function getResourceDataTypesDisplayValues(translationService?: TranslationService): ResourceDataTypesEnumMap {
  if (translationService) {
    return {
      [DataSourceDataTypes.label]: translationService.translate(`resourceDataTypes.${DataSourceDataTypes.label}`),
      [DataSourceDataTypes.month]: translationService.translate(`resourceDataTypes.${DataSourceDataTypes.month}`)
    };
  } else {
    return {
      [DataSourceDataTypes.label]: 'Data Label',
      [DataSourceDataTypes.month]: 'Administration Month'
    };
  }
}
