import {TranslationService} from '@shared/modules/translations/services/translation.service';

export enum SummariesTypes {
    Student_Search = 'student_search',
    Team_Boards = 'team_boards',
    Collab_Meetings = 'collab_meetings',
    User_Actions = 'user_actions',
    School_Actions = 'school_actions',
    Active_Support_Plans = 'active_support_plans',
    Inactive_Support_Plans = 'deactive_support_plans',
}

export enum FrequencyTypes {
    Daily = 'daily',
    Weekly = 'weekly',
}

export type SummariesTypesEnumMap = {
    [P in SummariesTypes]?: string;
};

export function getSummariesTypesDisplayValues(translationService: TranslationService): SummariesTypesEnumMap {
    return {
        [SummariesTypes.Student_Search]: translationService.translate(`summariesTypes.${SummariesTypes.Student_Search}`),
        [SummariesTypes.Team_Boards]: translationService.translate(`summariesTypes.${SummariesTypes.Team_Boards}`),
        [SummariesTypes.Collab_Meetings]: translationService.translate(`summariesTypes.${SummariesTypes.Collab_Meetings}`),
        [SummariesTypes.User_Actions]: translationService.translate(`summariesTypes.${SummariesTypes.User_Actions}`),
        [SummariesTypes.School_Actions]: translationService.translate(`summariesTypes.${SummariesTypes.School_Actions}`),
        [SummariesTypes.Active_Support_Plans]: translationService.translate(`summariesTypes.${SummariesTypes.Active_Support_Plans}`),
        [SummariesTypes.Inactive_Support_Plans]: translationService.translate(`summariesTypes.${SummariesTypes.Inactive_Support_Plans}`),
    };
}
