import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum StudentCardOrganizationFormats {
  first_name = 'first_name',
  last_name = 'last_name',
  grade = 'grade',
  manual = 'manual'
}

export type StudentCardOrganizationFormatsEnumMap = {
  [K in StudentCardOrganizationFormats]: string;
};

export function getStudentCardOrganizationFormatDisplayValues(translationService?: TranslationService): StudentCardOrganizationFormatsEnumMap {
  if (translationService) {
    return {
      [StudentCardOrganizationFormats.first_name]: translationService.translate(`studentCardOrganizationFormats.${StudentCardOrganizationFormats.first_name}`),
      [StudentCardOrganizationFormats.last_name]:  translationService.translate(`studentCardOrganizationFormats.${StudentCardOrganizationFormats.last_name}`),
      [StudentCardOrganizationFormats.grade]:  translationService.translate(`studentCardOrganizationFormats.${StudentCardOrganizationFormats.grade}`),
      [StudentCardOrganizationFormats.manual]:  translationService.translate(`studentCardOrganizationFormats.${StudentCardOrganizationFormats.manual}`)
    };
  } else {
    return {
      [StudentCardOrganizationFormats.first_name]: 'First Name - Organize cards by student first name in each tier',
      [StudentCardOrganizationFormats.last_name]: 'Last Name - Organize cards by student last name in each tier',
      [StudentCardOrganizationFormats.grade]: 'Grade - Organize cards by student grade in each tier',
      [StudentCardOrganizationFormats.manual]: 'Manual - Allow user to organize student card order in each tier'
    };
  }
}
