import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// models
import { IPageState } from '@shared/models/page-state.model';

// services
import { ExpansionPanelOCService } from '@shared/services/expansion-panel-oc.service';

/**
 * @Usage
 * 1. Set current url from parent component (once)
 * 2. Update page state (query params) in child components
 */
@Injectable({ providedIn: 'root' })
export class PageStateService {
  private currentUrl: string;

  public updatePageState$ = new Subject<IPageState>();

  public updateMultipleStates$ = new Subject<IPageState[]>();

  public updatingPageState$ = new Subject<boolean>();

  private readonly panelAndParamsSeparator = '--';
  private readonly paramKeyAndValueSeparator = '::';

  constructor(
    private readonly expansionPanelOCService: ExpansionPanelOCService,
    private readonly router: Router
  ) {
    this.updatePageState$
      // .pipe(debounceTime(100))
      .subscribe(state => this.updatePageState(state));

    this.updateMultipleStates$
      .pipe(debounceTime(100))
      .subscribe(states => this.updateMultipleStates(states));
  }

  public states: {
    [key: string]: IPageState; // key is the page name with expansion panel(s) and/or some inputs
  } = {
  };

  public setCurrentUrl(path: string): void {
    this.currentUrl = path;
    this.states = {};
  }

  public getRouterParamsFromPageStates(states?: { [key: string]: IPageState }): Params {
    const pageStates = states || this.states;
    const panelsQueries: string[] = [];
    const propertiesQuery: string[] = [];

    try {
      Object.values(pageStates).map(state => {
        // Page containing expansion panel(s) and/or some inputs
        if (state.pageOrPanelName && !state.isOnlyProperties) {
          if (!state || !state.pageOrPanelName || !Object.keys(state.params).length) {
            return;
          }

          const panelPropertiesQuery: string[] = [];
          Object.keys(state.params).map(key => {
            // skip empty keys (if there are for some reason)
            if (!!key) {
              // skip falsy values (0 is also considered falsy)
              if (!!state.params[key] && state.params[key] != 0) {
                panelPropertiesQuery.push(`${key}${this.paramKeyAndValueSeparator}${state.params[key]}`);
              }
            }
          });

          panelsQueries.push(`${state.pageOrPanelName}${this.panelAndParamsSeparator}${panelPropertiesQuery.join(this.panelAndParamsSeparator)}`);
        } else if (state.isOnlyProperties && state.params && Object.keys(state.params).length) {
          // Page containing only inputs
          Object.keys(state.params).map(key => {
            // skip falsy values (0 is also considered falsy)
            if (!!state.params[key] && state.params[key] != 0) {
              propertiesQuery.push(`${key}${this.paramKeyAndValueSeparator}${state.params[key]}`);
            }
          });
        }
      });
    } catch (ex) {
      console.error(ex);
      return {};
    }

    return {
      panels: panelsQueries.join(','),
      properties: propertiesQuery.join(',')
    };
  }

  public getPageStatesFromRouterParams(params: Params, pageOrPanelName: string): boolean {
    try {
      let thereWereQueryParams = false;

      if (!this.states) {
        this.states = {};
      }

      const panelsQuery: string = params.panels;
      const propertiesQuery: string = params.properties;

      if (panelsQuery) {
        thereWereQueryParams = true;

        const panels = panelsQuery.split(',');

        for (const panel of panels) {
          const state: IPageState = {
            pageOrPanelName: '',
            params: {}
          };

          const [panelName, ...params] = panel.split(this.panelAndParamsSeparator);

          state.pageOrPanelName = panelName;

          params.filter(keyValueString => !!keyValueString)
            .map(panelProperties => {
              const [key, value] = panelProperties.split(this.paramKeyAndValueSeparator);
              state.params[key] = value;
            });

          this.states[state.pageOrPanelName] = state;
        }
      }

      if (propertiesQuery) {
        thereWereQueryParams = true;
        const properties = propertiesQuery.split(',');

        const state: IPageState = {
          pageOrPanelName: pageOrPanelName,
          params: {},
          isOnlyProperties: true
        };

        for (const property of properties) {
          const [key, value] = property.split(this.paramKeyAndValueSeparator);
          state.params[key] = value;
        }

        this.states[state.pageOrPanelName] = state;
      }

      if (!thereWereQueryParams) {
        this.states[pageOrPanelName] = {
          pageOrPanelName: pageOrPanelName,
          params: {}
        };
      }

      return thereWereQueryParams;
    } catch (ex) {
      console.error(ex);
      return false;
    }
  }

  private updatePageState(state: IPageState): void {
    if (!this.states[state.pageOrPanelName]) {
      return;
    }

    this.updatingPageState$.next(true);

    this.states[state.pageOrPanelName] = state;

    this.router.navigate([this.currentUrl], { queryParams: this.getRouterParamsFromPageStates() });
  }

  public checkPageStateFromQueryParams(queryParams: Params, componentName: string): { isExpanded: boolean } | null {
    try {
      const gotStates = this.getPageStatesFromRouterParams(queryParams, componentName);
      if (gotStates) {
        const pageState = this.states[componentName];
        if (pageState) {
          if (pageState.params.isExpanded) {
            const isExpanded = typeof pageState.params.isExpanded === 'boolean' ? !!pageState.params.isExpanded : pageState.params.isExpanded === 'true';
            return { isExpanded };
          }
        } else {
          this.states[componentName] = {
            pageOrPanelName: componentName,
            params: {}
          };
        }

        return null;
      }

      return null;
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }

  private updateMultipleStates(states: IPageState[]): void {
    if (!states?.length) {
      return;
    }

    this.updatingPageState$.next(true);

    states.map(item => {
      this.states[item.pageOrPanelName] = item;
    });

    this.router.navigate([this.currentUrl], { queryParams: this.getRouterParamsFromPageStates()});
  }
}
