import {TranslationService} from '@shared/modules/translations/services/translation.service';

export enum ActionStatusTypes {
    incompleteInProgress = 'incomplete_in_progress',
    incomplete = 'incomplete',
    inProgress = 'in_progress',
    complete = 'complete',
    abandoned = 'abandoned'
}

export function getActionStatusTypeDisplayValues(translationService: TranslationService): { [key in ActionStatusTypes]: string } {
    return {
        [ActionStatusTypes.incompleteInProgress]: translationService.translate(`actionStatusTypes.${ActionStatusTypes.incompleteInProgress}`),
        [ActionStatusTypes.incomplete]: translationService.translate(`actionStatusTypes.${ActionStatusTypes.incomplete}`),
        [ActionStatusTypes.inProgress]: translationService.translate(`actionStatusTypes.${ActionStatusTypes.inProgress}`),
        [ActionStatusTypes.complete]: translationService.translate(`actionStatusTypes.${ActionStatusTypes.complete}`),
        [ActionStatusTypes.abandoned]: translationService.translate(`actionStatusTypes.${ActionStatusTypes.abandoned}`),
    };
}
