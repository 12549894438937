import { NotificationTypes } from '@shared/modules/notification-dialogs/models/notification-types.model';

export function getNotificationClassesByType(type: NotificationTypes): { containerClass: string, iconClass: string } {
  const classesConfig = {
    [NotificationTypes.SUCCESS]: {
      containerClass: 'notification-success',
      iconClass: 'ico-check-circle'
    },
    [NotificationTypes.WARNING]: {
      containerClass: 'notification-warning',
      iconClass: 'ico-warning-round'
    },
    [NotificationTypes.ERROR]: {
      containerClass: 'notification-error',
      iconClass: 'ico-error'
    },
    [NotificationTypes.ERROR_WITH_CLOSE]: {
      containerClass: 'notification-error',
      iconClass: 'ico-error'
    }
  };

  return classesConfig[type];
}
