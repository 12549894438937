// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://ctmrv2-api.codebnb.me/',
  HOST: 'https://ctmrv2-api.codebnb.me',
  API_V1: 'api/v1',
  socketUrl: 'wss://ctmrv2-api.codebnb.me',
  adminPassword: 'root00'
};

export const environmentSocial = {
  googleClientId: '278834910041-odjagnkru5vlq1jij10l5gnt7b7530ci.apps.googleusercontent.com',
  microsoftClientId: 'bf107cd7-e161-4856-b0a3-82efb40b4ae3', //client's provided ID
  // microsoftClientId: '3806c6db-58cb-4ec1-a026-85afca385a5e', //Harut's ID
  redirectUrl: 'http://localhost:4200',
};

/**
 * google sign in google acc
 * login:    -
 * password: -
 */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
