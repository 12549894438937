import {Directive, HostListener, OnDestroy, OnInit} from '@angular/core';
// services
import {AuthService} from "@auth/services/auth.service";

const timeoutUnit = 60 * 60 * 1000; // 60 minutes - 1 hour;

@Directive({
  selector: '[appInactivityCheck]'
})
export class InactivityCheckDirective implements OnInit, OnDestroy {

  private autoLogoutTimeout;

  constructor(private readonly authService: AuthService) { }

  ngOnInit(): void {
    this.checkUserInactivity();
  }

  @HostListener('mouseover', ['$event'])
  onMouseover($event) {
    this.checkUserInactivity();
  }

  private checkUserInactivity(): void {
    this.clearInactivityLogoutTimeout();
    this.setInactivityLogoutTimeout();
  }

  private setInactivityLogoutTimeout(): void {
    this.autoLogoutTimeout = setTimeout(() => {
      if (this.authService.isAuthenticated) {
        this.authService.logout();
      }
    }, timeoutUnit);
  }

  private clearInactivityLogoutTimeout(): void {
    if (this.autoLogoutTimeout) {
      clearTimeout(this.autoLogoutTimeout);
      this.autoLogoutTimeout = null;
    }
  }

  ngOnDestroy(): void {
    this.clearInactivityLogoutTimeout();
  }

}
