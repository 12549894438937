<div class="popup">

	<div class="popup-header">	

		<button mat-dialog-close class="close-btn"><i class="ico-close"></i></button>
		<h2>{{data.title}}</h2>

	</div>

	<div class="popup-body">

		<p class="confirmation-text">
			{{data.message}}
		</p>

	</div>

	<div class="popup-footer">

		<div class="action-buttons">
			<button *ngIf="data.showCancelBtn" mat-stroked-button class="btn btn-gray" (click)="close(false)">{{data.cancelBtnText}}</button>
			<button mat-raised-button cdkFocusInitial [class]="'btn ' + data.submitBtnClass" (click)="close(true)">{{data.submitBtnText}}</button>
		</div>	

	</div>

</div>
