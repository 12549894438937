import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';

// services
import { PermissionsService } from '@shared/services/permissions.service';
import { IUserPermissions } from '@shared/models/user-role-permissions.model';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private readonly permissionsService: PermissionsService
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.checkPermissions(route);

    // let redirectUrl: string;
    // if (userRoles.userType === Workspaces.ADMIN) {
    //   redirectUrl = `/${Workspaces.ADMIN}`;
    // } else if (userRoles.userType === Workspaces.DISTRICT) {
    //   redirectUrl = getRoute(Workspaces.DISTRICT, this.districtDataService.districtId, [DistrictRoutes.home]);
    // } else {
    //   redirectUrl = getRoute(Workspaces.SCHOOL, this.schoolDataService.schoolId, [DistrictRoutes.home]);
    // }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkPermissions(route);
  }

  private checkPermissions(route: Route | ActivatedRouteSnapshot): boolean {
    const permissions: IUserPermissions = route && route.data && route.data.permissions || undefined;
    if (this.permissionsService.hasPermission(permissions)) {
      return true
    }

    this.router.navigate(['/']);
    return false;
  }
}
