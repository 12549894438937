import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
// services
import { LocalStorageService } from '@shared/services/local-storage.service';
import { AuthService } from '@auth/services/auth.service';
import { NotificationService } from '@shared/modules/notification-dialogs/services/notification.service';
import { TranslationService } from '@shared/modules/translations/services/translation.service';
import { PermissionsService } from '@shared/services/permissions.service';
// models
import { NotificationTypes } from '@shared/modules/notification-dialogs/models/notification-types.model';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly translationService: TranslationService,
    private readonly permissionsService: PermissionsService,
    private readonly router: Router,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addTokenToRequest(req))
        // TODO open after b/e fixes
        // .pipe(map((res: HttpResponse<any>) => {
        //   if (res.headers && res.headers.has('auth_token')) {
        //     const authToken = res.headers.get('auth_token');
        //     this.localStorageService.setToken(authToken);
        //   }
        //   return res;
        // }))
      .pipe(catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 403:
              this.notificationService.showNotification(NotificationTypes.WARNING, this.translationService.translate('noPermission'));
              this.router.navigate(['/']);
              return EMPTY;
            case 401:
              if (this.authService.isAuthenticated) {
                this.authService.logout();
              }

              // Cuz of returning EMPTY /auth route is not loaded and app is stopped at Splash Screen page
              if (this.permissionsService.permissionInitComplete$.value) {
                return EMPTY;
              }
              return throwError(error);
            case 500:
              this.notificationService.showNotification(NotificationTypes.ERROR, this.translationService.translate('responses.internalServerError'));
              break;
          }

          return throwError(error);
        } else {
          return throwError(error);
        }
      }));
  }

  private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.localStorageService.getToken();
    if (!token) {
      return request;
    }
    return request.clone({ setHeaders: { Authorization: token } });
  }
}
