import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum InformationEntryTypes {
  manual = 'manual',
  sis = 'sis'
}

export function getInfoEntryTypeDisplayValues(translationService: TranslationService): {[key in InformationEntryTypes]: string} {
  if (translationService) {
    return {
      [InformationEntryTypes.manual]: translationService.translate(`informationEntryTypes.${InformationEntryTypes.manual}`),
      [InformationEntryTypes.sis]: translationService.translate(`informationEntryTypes.${InformationEntryTypes.sis}`),
    };
  } else {
    return {
      [InformationEntryTypes.manual]: 'Manual',
      [InformationEntryTypes.sis]: 'SIS Field',
    };
  }
}
