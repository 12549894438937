export enum ActionTableColumnsModel {
    ACTION = 'name',
    RESPONSIBLE = 'responsible',
    EXPECTED_DATE = 'completion_date',
    ACTUAL_DATE = 'actual_date',
    COMPLETED_AT = 'completed_at',
    STUDENT = 'student',
    STATUS = 'status',
    SCHOOL = 'school',
    MEETING_NOTE = 'school_meeting_note',
    COMMENT = 'comment'
}
