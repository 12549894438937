import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// models
import { IDistrict } from '@admin/models/district.model';
import { SchoolModel } from '@school/models/school.model';

@Injectable({ providedIn: 'root' })
export class DistrictAndSchoolDataService {
  public update$ = new Subject<{ type: 'resolving' | 'resolved' }>();

  public district: IDistrict;
  public school: SchoolModel;

  // Used to update logo in header, when it's changed in System Defaults Edit Logo page
  public logoChange = new Subject<string>();

  // Extra properties set in frontend side
  public districtCreatedYear: number;

  public get districtID(): number {
    return this.district ? this.district.id : null;
  }

  public get schoolID(): number {
    return this.school ? this.school.id : null;
  }
}
