export enum AdminRoutes {
  home = '',
  addDistrict = 'add-district',
  systemDefaults = 'system-defaults',
  homePageUpdates = 'home-page-updates',
  systemUsers = 'system-users',
  syncData = 'sync-data',
  editUserEmails = 'edit-user-emails',
  supports = 'student-supports',
  accessStudentNetwork = 'student-support-network',
  addSliderData = 'add-slider-data',
  addTestimonialData = 'add-testimonials-data',
  addSchoolHomePageLink = 'add-school-home-page-link',
  editSchool = 'edit-school',
  editDistrict = 'edit-district'
}

export enum DistrictRoutes {
  home = '',
  schools = 'schools',
  schoolDefaults = 'defaults',
  staff = 'staff',
  meetings = 'meetings',
  plans = 'plans',
  supports = 'supports',
  accessStudentNetwork = 'student-support-network',
  actions = 'actions',
  reports = 'reports',
  resources = 'resources',
  sync = 'sync',
  system = 'system',
  studentBoardView = 'board',
  schoolBoardView = 'board/school-board',
  configurations = 'configurations',
  schoolProfile = 'school-profile',
  startSchoolMeeting = 'start-school-meeting',
  editSchoolMeeting = 'edit-school-meeting',
  scheduleSchoolMeeting = 'schedule-school-meeting',
  addSchoolBoard = 'add-district-team-board-schools',
  editSchoolBoard = 'edit-district-team-board-schools',
  addStudentIntegratedBoard = 'add-integrated-team-board-students',
  editStudentIntegratedBoard = 'edit-integrated-team-board-students',
  addStudentDesegregatedBoard = 'add-district-team-board-students',
  editStudentDesegregatedBoard = 'edit-district-team-board-students',
  schoolMeetingNote = 'school-meeting-note',
  userSupports = 'user-supports',
}

export enum SchoolRoutes {
  selectSchool = 'select-school',
  home = '/',
  configure = 'configure',
  addEdit = 'add-edit',
  system = 'system',
  students = 'students',
  studentGeneral = 'general',
  studentProfile = 'profile',
  manageIndividualStudentMeeting = 'manage-individual-student-meeting',
  plans = 'plans',
  boards = 'boards',
  boardView = 'board',
  addDefaultBoard = 'add-team-board',
  editDefaultBoard = 'edit-team-board',
  addCustomBoard = 'add-custom-team-board',
  editCustomBoard = 'edit-custom-team-board',
  supports = 'supports',
  userSupports = 'user-supports',
  accessStudentNetwork = 'student-support-network',
  actions = 'actions',
  resources = 'resources',
  reports = 'reports',
  search = 'search',
  studentCardIcons = 'student-card-icons',
  configurations = 'configurations',
  supportPlan = 'support-plan',
  assessments = 'specialized-assessment',
  goalPlanning = 'goal-planning',
  editCurrentStudents = 'edit-current-students',
  manageStudentsSubscription = 'manage-subscription',
  manageStudentMeetingNote = 'manage-student-meeting-note',
}
