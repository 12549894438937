import { SchoolStatuses } from '@school/models/school-statuses.model';
import { UserRoles } from '@shared/models/user-types.model';
import { SupportPlanPermissions } from '@school/models/teacher-support-plan-permissions.model';
import { Months } from '@shared-pages/system-defaults/models/student-data/month.model';
import { CategoryResultTypes } from '@shared-pages/system-defaults/models/student-data/student-data-result-types.model';
import { DataSourceDataTypes } from '@shared-pages/system-defaults/models/student-data/resource-data-types.model';
import { MeetingPanelTypes } from '@shared-pages/boards/models/meeting-panel-names.model';
import { StudentCardOrganizationFormats } from '@shared-pages/boards/models/student-card-organization.model';
import { StudentCardDisplayTypes } from '@shared-pages/boards/models/student-card-display-types.model';
import { StudentCardNameTypes } from '@shared-pages/boards/models/student-card-name-types.model';
import { Gender } from '@shared/models/gender.model';
import { FilterOperators } from '@shared/components/filter-students/models/filter-operators.model';
import { SupportTypes } from '@shared-pages/supports/models/support-types.model';
import { SchoolProfilePanelsTypes } from "@district/modules/school-profile/models/school-profile-panels-names.model";
import { InformationEntryTypes } from '@shared-pages/plans/modules/configurations/models/information-entry-types.model';
import { IppTableColumns } from '@shared-pages/plans/models/ipp-table-columns.model';
import { ActionPanelTypes } from "@shared-pages/actions/models/action-panel-types.model";
import { ActionTableColumnsModel } from "@shared-pages/actions/models/action-table-columns.model";
import { ActionStatusTypes } from "@shared-pages/actions/models/action-status-types.model";
import {SummariesTypes} from "@shared/components/dialogs/user-settings/models/summaries-types.model";

export function getFrenchTranslations(): any {
  return {
    APP_NAME: 'FR CTMR',
    pages: {
      auth: {
        main: {
          title: 'La solution pour assurer une approche collaborative',
          description: 'Ce logiciel est conçu pour appuyer les écoles et les conseils scolaires à répondre aux besoins diversifiés de leurs élèves ainsi qu\'à assurer une coordination de la mise en application de stratégies par l\'équipe.',
          googleSignIn: 'Connectez-vous avec Google',
          microsoftSignIn: 'Connectez-vous avec Microsoft',
          emailSignIn: 'Connectez-vous par e-mail'
        },
        partnersList: {
          title: 'Nos partenaires',
          subTitle: 'Vous souhaitez en savoir plus sur le système <span class="color-main-blue">We</span><span class="color-main-orange">Collab</span>?',
          requestInformation: 'Informations Requises',
          watchVideo: 'Regarder la Vidéo',
        },
        testimonials: {
          we: 'We',
          collab: 'Collab',
          testimonials: 'Témoignages'
        },
        footer: {
          copyright: 'FR COPYRIGHT',
          copyrightRights: 'FR JIGSAW LEARNING INC. ALL RIGHTS RESERVED.',
          termsAndConditions: 'FR Terms and Conditions',
          privacyPolicy: 'FR Privacy Policy',
          poweredBy: 'French Powered by ',
          STDev: 'STDev'
        },
        header:{
          learnMore:'FR Learn More',
          freeTrial:'FR Free Trial',
          scheduleConversation:'FR Schedule a Conversation'
         },
        suspendedAccountWarning: 'FR Disabled schools are currently suspended',
        agreementTitle: 'FR User Agreement',
        agree: 'FR Agree',
        disagree: 'FR Disagree',
        exit: 'FR Exit',
        disagreeDialogMessage: 'FR Exiting without agreeing to the user agreement will result in being signed off the system․',
      }
    },
    noData: 'FR No Data',
    noFieldValue: 'FR N/A',
    noItems: 'FR No items found',
    noIcons: 'FR There are no icons',
    notAvailable: 'FR Not Available',
    totalStudents: 'FR Total Students',
    doNotAutoDelete: 'FR Do Not Auto-Delete',
    noPermission: 'FR You don\'t have permissions to view this page',
    addStudentsToSchoolDescription: 'FR To return students to the active roster, select student(s) and then press "Add to School".',
    pagination: {
      first: 'FR First',
      last: 'FR Last',
      showing: 'FR Showing',
      of: 'FR of',
      notFound: 'FR No result is found'
    },
    name: 'FR Name',
    type: 'FR Type',
    date: 'FR Date',
    documents: 'FR Documents',
    webLinks: 'FR Web Links',
    noDocumentsAdded: 'FR There are no documents added',
    noLinksAdded: 'FR There are no links added',
    loading: 'FR Loading...',
    current: 'FR Current',
    past: 'FR Past',
    access: 'FR Access Permissions',
    labels: {
      principal: 'FR Principal',
      users: 'FR Users',
      district: 'FR District',
      focusName: 'FR Focus Name',
      goal: 'FR Goal',
      deletedAttendances: 'FR Deleted Attendees',
      deletedRecipients: 'FR Deleted Recipients',
      otherSchoolAttendances: 'FR Attendees From Another School',
      activeUsers: 'FR Active Users',
      activeDistricts: 'FR Active Districts',
      activeSchools: 'FR Active Schools',
      activeStudents: 'FR Active Students',
    },
    tables: {
      move: 'FR Move',
      role: 'FR Role',
    },
    schoolStatuses: {
      [SchoolStatuses.good]: 'FR Active',
      [SchoolStatuses.payment_pending]: 'FR Pending',
      [SchoolStatuses.account_suspended]: 'FR Suspended'
    },
    userTypes: {
      [UserRoles.District_Leader]: 'FR District Leader',
      [UserRoles.District_Staff]: 'FR District Staff',
      [UserRoles.District_Admin]: 'FR District Admin',
      [UserRoles.School_Teacher]: 'FR School Teacher',
      [UserRoles.School_Leader]: 'FR School Leader',
      [UserRoles.School_Staff]: 'FR School Staff',
      [UserRoles.School_Admin]: 'FR School Admin'
    },
    summariesTypes: {
      [SummariesTypes.Student_Search]: 'FR Student Search',
      [SummariesTypes.Team_Boards]: 'FR Team Boards',
      [SummariesTypes.Collab_Meetings]: 'FR Collaborative Team Meetings',
      [SummariesTypes.User_Actions]: 'FR User Actions',
      [SummariesTypes.School_Actions]: 'FR School Actions',
      [SummariesTypes.Active_Support_Plans]: 'FR Active Support Plans',
      [SummariesTypes.Inactive_Support_Plans]: 'FR Deactivated Support Plans',
    },
    supportPlanPermissions: {
      [SupportPlanPermissions.NO_SELECT]: 'FR Not Set',
      [SupportPlanPermissions.IPP_COORDINATOR]: 'FR Support Plan Coordinator',
      [SupportPlanPermissions.IPP_ADMIN]: 'FR Support Plan Admin',
      [SupportPlanPermissions.IPP_TEACHER]: 'FR Support Plan Teacher',
    },
    studentCardOrganizationFormats: {
      [StudentCardOrganizationFormats.first_name]: 'FR First Name - Organize cards by student first name in each tier',
      [StudentCardOrganizationFormats.last_name]: 'FR Last Name - Organize cards by student last name in each tier',
      [StudentCardOrganizationFormats.grade]: 'FR Grade - Organize cards by student grade in each tier',
      [StudentCardOrganizationFormats.manual]: 'FR Manual - Allow user to organize student card order in each tier'
    },
    studentCardDisplayTypes: {
      [StudentCardDisplayTypes.name]: 'FR Name Only',
      [StudentCardDisplayTypes.minimal_name_bar]: 'FR Minimal Name Bar',
      [StudentCardDisplayTypes.picture]: 'FR Picture Only',
      [StudentCardDisplayTypes.name_picture]: 'FR Name and Picture',
      [StudentCardDisplayTypes.name_picture_data]: 'FR Name, Picture and Data',
      [StudentCardDisplayTypes.name_data]: 'FR Name and Data',
    },
    studentCardNameTypes: {
      [StudentCardNameTypes.first_name_last_name]: 'FR First Name, Last Name',
      [StudentCardNameTypes.last_name_first_name]: 'FR Last Name, First Name',
      [StudentCardNameTypes.first_name_last_initial]: 'FR First Name Last Initial',
      [StudentCardNameTypes.last_name_first_initial]: 'FR Last Name, First Initial'
    },
    informationEntryTypes: {
      [InformationEntryTypes.manual]: 'FR Manual',
      [InformationEntryTypes.sis]: 'FR SIS Field'
    },
    ippTableColumns: {
      [IppTableColumns.STUDENT_NAME]: 'FR Student name',
      [IppTableColumns.TEMPLATE]: 'FR Template',
      [IppTableColumns.SCHOOL]: 'FR School',
      [IppTableColumns.TEACHER]: 'FR Teacher',
      [IppTableColumns.LAST_REVISION]: 'FR Last Revision',
      [IppTableColumns.SIGNATURES]: 'FR Signatures',
      [IppTableColumns.DEACTIVATION_DATE]: 'FR Deactivated Date'
    },
    actionTableColumns: {
      [ActionTableColumnsModel.ACTION]: 'FR Action',
      [ActionTableColumnsModel.RESPONSIBLE]: 'FR Responsible',
      [ActionTableColumnsModel.EXPECTED_DATE]: 'FR Expected Date',
      [ActionTableColumnsModel.ACTUAL_DATE]: 'FR Actual Date',
      [ActionTableColumnsModel.STATUS]: 'FR Status',
      [ActionTableColumnsModel.STUDENT]: 'FR Student',
      [ActionTableColumnsModel.SCHOOL]: 'FR School',
      [ActionTableColumnsModel.MEETING_NOTE]: 'FR Meeting/Meeting Note',
      [ActionTableColumnsModel.COMMENT]: 'FR Comment',
    },
    actionStatusTypes: {
      title: 'FR Action Status',
      [ActionStatusTypes.incompleteInProgress]: 'FR Incomplete/In Progress',
      [ActionStatusTypes.incomplete]: 'FR Incomplete',
      [ActionStatusTypes.inProgress]: 'FR In Progress',
      [ActionStatusTypes.complete]: 'FR Complete',
      [ActionStatusTypes.abandoned]: 'FR Abandoned',
    },
    months: {
      [Months.January]: 'Janvier',
      [Months.February]: 'Fèvrier',
      [Months.March]: 'Mars',
      [Months.April]: 'Avril',
      [Months.May]: 'Mai',
      [Months.June]: 'Juin',
      [Months.July]: 'Juillet',
      [Months.August]: 'Aout',
      [Months.September]: 'Septembre',
      [Months.October]: 'Octobre',
      [Months.November]: 'Novembre',
      [Months.December]: 'Decembrer'
    },
    studentDataResultTypes: {
      [CategoryResultTypes.manual]: 'FR Manual',
      [CategoryResultTypes.dropdown]: 'FR Dropdown'
    },
    resourceDataTypes: {
      [DataSourceDataTypes.label]: 'FR Data Label',
      [DataSourceDataTypes.month]: 'FR Administration Month'
    },
    genders: {
      [Gender.MALE]: 'FR Male',
      [Gender.FEMALE]: 'FR Female',
      [Gender.OTHER]: 'FR Other'
    },
    filterOperators: {
      [FilterOperators.more_than]: 'FR More than',
      [FilterOperators.less_than]: 'FR Less than',
      [FilterOperators.equal]: 'FR Equal to',
      [FilterOperators.not_equal]: 'FR Not equal to',
      [FilterOperators.contain]: 'FR Contains',
      [FilterOperators.not_contain]: 'FR Not contain'
    },
    studentSupportTypes: {
      [SupportTypes.accommodation]: 'FR Accommodation',
      [SupportTypes.strategy]: 'FR Strategy',
      [SupportTypes.intervention]: 'FR Intervention'
    },
    responses: {
      notFound: 'FR Not Found',
      internalServerError: 'FR Internal Server Error',
      unknown: 'FR Unknown error occurred',
      invalidPage: 'FR Invalid page, returning to page 1',
      success: 'FR Success',
      created: 'FR Created',
      updated: 'FR Updated',
      pdfError: 'FR Could not download PDF',
      fileDownloadError: 'Could not download file',
      couldNotPerformAction: 'FR Could not perform action',
      failure: 'FR The process is interrupted.',
      couldNotGetData: 'FR Could not get data',
      couldNotUploadFileCheckInputs: 'FR Could not upload file. Please check your inputs',
      teamBoardCreated: 'FR Team board created successfully',
      teamBoardUpdated: 'FR Team board updated successfully',
      teamBoardCreateError: 'FR Could not create team board',
      teamBoardUpdateError: 'FR Could not update team board',
      statistics: 'FR Could not get statistics',
      successZipUpload: 'FR Your upload is in progress. We will notify you via email once it\'s completed.'
    },
    fields: {
      firstName: 'FR First Name',
      lastName: 'FR Last Name',
      email: 'FR Email',
      user: 'FR User',
      search: 'FR Search',
      selectSchool: 'FR Select School',
      selectSchools: 'FR Select Schools',
      selectDate: 'FR Select Date',
      selected: 'FR selected',

      grade: 'FR Grade',
      studentName: 'FR Student Name',
      schoolName: 'FR School Name',
      gender: 'FR Gender',
      studentNumber: 'FR Student Number',
      studentSisNumber: 'FR Student SIS Number',
      graduatedYear: 'FR Graduated Year',
      archiveDate: 'FR Archive Date',

      permission: 'FR Permission',

      addNote: 'FR Add Note',

      selectFolder: 'FR Select Folder',

      addfile: 'FR Add File',
      editfile: 'FR Edit File',
      addlink: 'FR Add Link',
      editlink: 'FR Edit Link',
      file: 'FR File',
      link: 'FR Link',
      fileName: 'FR File Name',
      webLinkName: 'FR Web Link Name',
      url: 'FR URL',

      selectStaff: 'FR Select Staff',
      selectAttendees: 'FR Select Attendees',
      staffGroups: 'FR Staff Groups',
      individualUsers: 'FR Individual Users',
      externalTeamMembers: 'FR External Team Members',

      comment: 'FR Comment',

      usersWithoutGroups: 'FR Users without groups',
      selectTeamBoard: 'FR Select Team Board',

      expectedCompletionDate: 'FR Expected Completion Date',
      school: 'FR School',

      selectGender: 'FR Select Gender',
      selectGrade: 'FR Select Grade',

      availableStudents: 'FR Available Students',
      selectedStudents: 'FR Selected Students',
      availableSchools: 'FR Available Schools',
      selectedSchools: 'FR Selected Schools',

      nextReviewDate: 'FR Next Review Date',

      selectedAttendees: 'FR Selected Attendees',

      emailOptional: 'FR Email (Optional)',
      emailRequired: 'FR Email (Required)',

      and: 'Fr And',
      or: 'Fr Or',

      entryLevel: 'FR Student Entry Level',
      tier: 'FR Tier of Support',

      teachers: 'FR Select Teachers',
      boards: 'FR Select Team Boards',

      boardName: 'FR Board Name',
      focus: 'FR Focus',
      searchStudent: 'FR Search Student',
      searchSchool: 'FR Search School',
      selectNote: 'FR Select Student Programming Note',
      programmingNote: 'FR Student Programming Note',
      selectCardIcon: 'FR Select Student Card Icon',
      studentAlerts: 'FR Student Alerts',
      schoolAlerts: 'FR School Alerts',

      teacher: 'FR Teacher',

      customizeTable: 'FR Customize Table',

      signature: 'FR Signature',

      selectStudentSupportType: 'FR Select Student Support type',

      responsible: 'FR Responsible',
      completionDate: 'FR Completion Date',
      startDate: 'FR Start Date',
      endDate: 'FR End Date',
      reviewDate: 'FR Review Date',
      rating: 'FR Rating',

      allowedFileTypes: 'FR Allowed file types',

      editCurrentStudents: 'FR Edit Current Student(s)',
      uploadMultiplePhotos: 'FR Upload Multiple Photos',
      multipleStudentDataEntry: 'FR Multiple Student Data Entry',
      manageStudentSubscription: 'FR Manage Student Subscription',

      createdBy: 'FR Created By',

      districtAlert: 'FR District Alert',
      schoolAlert: 'FR School Alert',

      filterWarning: 'FR Students data has been changed. Click "Apply Filters" to see filtered results.',

      selectTeamBoardCategories: 'FR Select Team Board Categories',
      selectGroups: 'FR Select Groups',
      selectUsers: 'FR Select Users',
      addBoardCategory: 'FR Add Board Category',
      upTo20Permitted: 'FR (up to 20 categories permitted)',
      maximumItemsTooltip: 'FR Maximum 20 items can be added',

      color: 'FR Color',
      title: 'FR Title',
      subtitle: 'FR Subtitle',
      subtitleOptional: 'FR Category Sub-Title (optional)',
      default: 'FR Default',
      teamBoardDefaultIsRequired: 'FR Please choose Default Team Board Category',
      boardCategoriesAreEmpty: 'FR Please add at least one Team Board Category',

      additionalTasks: 'FR Additional tasks',
      norms: 'FR Norms',
      normsUncheck: 'FR Please note that in the case of editing/deleting, changes will only affect upcoming meetings.',
      addNorms: 'FR Add Norms',
      uncheckAddNormsWarning: 'FR Please note that the Norms have been used in the meeting(s). Are you sure you want to remove it for the upcoming meetings?',
      action: 'FR Action',
      status: 'FR Status',
      actions: 'FR Actions',
      selectCategory: 'FR Select Category',
      selectTeacher: 'FR Select Teacher',
      selectRecipients: 'FR Select Recipients',
      selectStudents: 'FR Select Student(s)',
      selectAdditionalStudents: 'FR Select Additional Students',
      includedInAMeeting: 'FR Meeting Note Added',

      additionalNotes: 'FR Additional Notes',
      selectSupportToSeeStudents: 'FR Select Support to see students list',

      addStudentCelebrations: 'FR Add Student Celebrations',
      addSchoolCelebrations: 'FR Add School Celebrations',
      celebration: 'FR Celebration',
      studentPlural: 'FR Student(s)',
      schoolPlural: 'FR School(s)',
      keyIssue: 'FR Key Issue',
      context: 'FR Context',
      selectAdditionalSchools: 'FR Select Additional Schools',
      linkToSupportPlanSubGoal: 'FR Link to Support Plan Sub-Goal',
      usersResponsible: 'FR User(s) Responsible',
      selectFocus: 'FR Select Focus',
      selectSupport: 'FR Select Support'
    },
    errorMessages: {
      noFileSelected: 'FR Please select a file',
      noCsvFileSelected: 'FR Please select a CSV file',
      selectStudent: 'FR Please select at least one student',
    },
    formInputErrors: {
      min: 'FR Value must be greater than or equal to',
      max: 'FR Value must be less than or equal to',
      required: 'FR Required',
      notUnique: 'FR Not unique',
      email: 'FR Invalid email',
      minLength: 'FR Please enter at least {minlength} characters',
      maxLength: 'FR Please enter maximum {maxlength} characters',
      exists: 'FR Exists',
      onlyNumbers: 'FR Please enter only numbers',
      emailAlreadyUsed: 'FR Some of emails are already used or are invalid',
      invalidPhoneNumber: 'FR Invalid phone number',
      invalidFileType: 'FR Invalid file type',
      maxAllowedFileSize: 'FR File size should be up to {maxAllowedFileSize} MB',
      matDatepickerParse: 'FR Invalid date format',
      matDatepickerFilter: 'FR This date cannot be selected',
      dateNotAllowed: 'FR This date cannot be selected',
      googleDocURL: 'FR Please make sure to provide a valid Google Doc URL',
      video: 'FR Please make sure to provide a valid Youtube video URL',
    },
    errorTexts: {
      row: 'FR Row',
      field: 'FR Field',
      errors: 'FR Errors'
    },
    expansionPanels: {
      collapse: 'FR Collapse',
      expand: 'FR Expand',
      expandAll: 'FR Expand All',
      shrinkAll: 'FR Collapse All'
    },
    image: {
      upload: 'FR Select Image',
      change: 'FR Change Image',
      dnd: ' FR or Drag/Drop here'
    },
    buttons: {
      save: 'FR Save',
      saveAndContinue: 'FR Save And Continue',
      cancel: 'FR Cancel',
      view: 'FR View',
      add: 'FR Add',
      edit: 'FR Edit',
      new: 'FR New',
      addNew: 'FR Add New',
      close: 'FR Close',
      andAndAddAnother: 'FR Save and Add Another',
      selectAll: 'FR Select All',
      deselectAll: 'FR Deselect All',
      yes: 'FR Yes',
      no: 'FR No',
      addMore: 'FR Add More',
      continue: 'FR Continue',
      uploadFile: 'FR Upload file',
      changeFile: 'FR Change File',
      import: 'FR Import',
      approve: 'FR Approve',
      reject: 'FR Reject',
      addFolder: 'FR Add Folder',
      editFolder: 'FR Edit Folder',
      addResource: 'FR Add Resource',
      editResource: 'FR Edit Resource',
      addToSchool: 'FR Add to School',
      delete: 'FR Delete',
      start: 'FR Start',
      schedule: 'FR Schedule',
      addNewAction: 'FR Add New Action',
      viewPDF: 'FR View PDF',
      exportCSV: 'FR Export CSV',
      exportPDF: 'FR Export PDF',
      teamBoardAccess: 'FR Team Board Access',
      resetData: 'FR Reset Data',
      clearFilter: 'FR Clear Filter',
      applyFilter: 'FR Apply Filter',
      saveAndView: 'FR Save and View',
      previewPDF: 'FR Preview PDF',
      next: 'FR Next',
      remove: 'FR Remove',
      returnToTeamBoard: 'FR Return to Team Board',
      endMeeting: 'FR End Meeting',
      previous: 'FR Previous',
      switchToProfile: 'FR Switch to Profile',
      switchToRecord: 'FR Switch to Record',
      addToContext: 'FR Add To Context',
      generatePDF: 'FR Generate PDF',
      generateCSV: 'FR Generate CSV',
    },
    sidebarNavigation: {
      welcome: 'FR Welcome',
      support: 'FR Support',
      feedback: 'FR Feedback',
      reportAnIssue: 'FR Report an Issue',
      poweredBy: 'FR Powered By',
      globalNavigationItems: {
        superOptions: 'FR Super Options',
        districtOptions: 'FR District Options',
        schoolOptions: 'FR School Options'
      },
      subsidiaryOf: 'FR A subsidiary of'
    },
    tooltips: {
      isEditing: 'FR is Editing',
      unlockAndEdit: 'FR Unlock and Edit',
      scheduled: 'FR Scheduled',
      unlock: 'FR Unlock',
      lock: 'FR Lock',
      locked: 'FR Locked',
    },
    notifications: {
      unlocked: 'FR One of the leaders unlocked the page. Please note that your changes won’t be saved.',
      editingByAnother: 'FR This page is being edited by another user',
      copyToClipboard:"FR Copied to clipboard"
    },
    pageNames: {
      systemStatisticsOverview: 'FR System Statistics Overview',
      schoolAccount: 'FR School Account',
      districtAccount: 'FR District Account',
      defaults: 'FR Defaults',
      supportLinks: 'FR Support Links',
      searchUsers: 'FR Search Users',
      accountCredentials: 'FR Account Credentials',
      connectedDistricts: 'FR Connected Districts',
      editUserEmails: 'FR Edit User Emails',
      studentSupports: 'FR Student Supports',
      studentSupportNetwork: 'FR Student Support Network',
      viewStudentSupport: 'FR View student support',
      importStudentSupport: 'FR Import a student support',
      addStudentSupport: 'FR Add Student Support',
      manageSupport: 'FR Manage Support',
      editStudentSupport: 'FR Edit Student Support',
      editNewStudentSupport: 'FR Edit New Student Support',
      home: 'FR Home',
      addSliderData: 'FR Add Slider Data',
      updateSliderData: 'FR Update Slider Data',
      addTestimonialData: 'FR Add Testimonial Data',
      updateTestimonialData: 'FR Update Testimonial Data',
      addSchoolPageLink: 'FR Add School Page Link',
      updateSchoolPageLink: 'FR Update School Page Link',
      updateVideo: 'FR Update Video',

      studentSearch: 'FR Student Search',
      advancedStudentSearch: 'FR Advanced Student Search',
      graduatedStudents: 'FR Graduated Students',
      archivedStudents: 'FR Archived Students',
      editCurrentStudents: 'FR Edit Current Students',
      manageStudentSubscription: 'FR Manage Student Subscription',
      exportStudents: 'FR Export Students',

      // System Defaults
      studentProfileConfigurations: 'FR Student Profile Configurations',
      studentProfileTextboxes: 'FR Student Profile Textboxes',
      editStudentProfileTextboxes: 'FR Edit Student Profile Textboxes',
      studentProgrammingNotes: 'FR Student Programming Notes',
      editStudentProgrammingNotes: 'FR Edit Student Programming Notes',
      meetingDescriptors: 'FR Meeting Descriptors',
      editMeetingDescriptors: 'FR Edit Meeting Descriptors',
      studentData: 'FR Student Data',
      editStudentData: 'FR Edit Student Data',
      editSchoolDefaults: 'FR Edit School Defaults',


      teamBoardConfigurations: 'FR Team Board Configurations',
      grades: 'FR Grades',
      editGrades: 'FR Edit Grades',
      studentSupportFocusAreas: 'FR Student Support Focus Areas',
      editStudentSupportFocusAreas: 'FR Edit Student Support Focus Areas',
      studentEntryLevels: 'FR Student Entry Levels',
      editStudentEntryLevels: 'FR Edit Student Entry Levels',
      studentSupportTiers: 'FR Student Support Tiers',
      editStudentSupportTiers: 'FR Edit Student Support Tiers',
      studentCardIcons: 'FR Student Card Icons',
      entryLevels: 'FR Entry Levels',
      editStudentCardIcons: 'FR Edit Student Card Icons',
      viewNotes: 'FR View Notes',
      startSchoolMeeting: 'FR Start School Meeting',
      editSchoolMeeting: 'FR Edit School Meeting',
      scheduleSchoolMeeting: 'FR Schedule School Meeting',
      addSchoolMeetingNote: 'FR Add School Meeting Note',
      editSchoolMeetingNote: 'FR Edit School Meeting Note',

      supportPlanConfigurations: 'FR Support Plan Configurations',
      supportTeamRole: 'FR Support Team Role',
      editSupportTeamRole: 'FR Edit Support Team Role',
      supportPlanInformation: 'FR Support Plan Information',
      editSupportPlanInformation: 'FR Edit Support Plan Information',

      transitionPlanning: 'FR Transition Planning',
      editTransitionPlanning: 'FR Edit Transition Planning',
      reviewDate: 'FR Review Date',
      editReviewDate: 'FR Edit Review Date',
      signatures: 'FR Signatures',
      generalConfigurations: 'FR General Configurations',

      // District
      districtHome: 'FR District Options',
      resources: 'FR Resources',

      // Meetings
      meetings: 'FR Meetings',
      [MeetingPanelTypes.boardsSchools]: 'FR District Team Boards - Schools',
      [MeetingPanelTypes.collaborativeSchools]: 'FR District Collaborative Team Meetings - Schools',
      [MeetingPanelTypes.schoolProfile]: 'FR School Profile',
      [MeetingPanelTypes.boardsStudentsIntegrated]: 'FR Integrated Team Board - Students',
      [MeetingPanelTypes.boardsStudentsDesegregated]: 'FR District Team Board - Students',
      [MeetingPanelTypes.collaborativeStudents]: 'FR District Collaborative Team Meetings - Students',
      addDistrictTeamBoardSchools: 'FR Add District Team Board',
      editDistrictTeamBoardSchools: 'FR Edit District Team Board',
      addDistrictTeamBoardStudentsIntegrated: 'FR Add Integrated Team Board For Students',
      editDistrictTeamBoardStudentsIntegrated: 'FR Edit Integrated Team Board For Students',
      addDistrictTeamBoardStudentsDesegregated: 'FR Add District Team Board For Students',
      editDistrictTeamBoardStudentsDesegregated: 'FR Edit District Team Board For Students',

      // School Profiles
      [SchoolProfilePanelsTypes.schoolMeetings]: 'FR School Meetings',
      [SchoolProfilePanelsTypes.schoolActions]: 'FR School Actions',
      [SchoolProfilePanelsTypes.schoolResources]: 'FR School Resources',

      // Actions
      [ActionPanelTypes.districtActions]: 'FR District Actions',
      [ActionPanelTypes.myActions]: 'FR My Actions',
      [ActionPanelTypes.studentActions]: 'FR Student Actions',

      // District -> Schools
      districtSchools: 'FR Schools',
      districtConfigurations: 'FR District Configurations',
      logo: 'FR Logo',
      districtMeetingDescriptions: 'FR District Meeting Descriptors',
      editDistrictMeetingDescriptions: 'FR Edit District Meeting Descriptors',
      schoolTextboxes: 'FR School Textboxes',
      editSchoolTextboxes: 'FR Edit School Textboxes',

      addEdit: 'FR Add/Edit',
      addStudent: 'FR Add New Student',
      students: 'FR Students',
      automaticAssignment: 'FR Automatic Assignment',
      manualAssignment: 'FR Manual Assignment',
      editGradeTeacher: 'FR Edit Grade/Teacher',

      staff: 'FR Staff',
      plans: 'FR Plans',

      // School
      schoolOptions: 'FR School Options',
      schoolConfigure: 'FR School Configure',
      schoolConfigs: 'FR School Configurations',

      // Boards
      [MeetingPanelTypes.boardsSchoolLevel]: 'TFR eam Boards',
      [MeetingPanelTypes.collaborativeSchoolLevel]: 'FR Collaborative Team Meetings',
      addSchoolTeamBoard: 'FR Add Team Board',
      editSchoolTeamBoard: 'FR Edit Team Board',
      addCustomSchoolTeamBoard: 'FR Add Custom Team Board',
      editCustomSchoolTeamBoard: 'FR Edit Custom Team Board',

      // Supports
      supports: 'FR Supports',

      // Plans
      activatedIpp: 'FR Active Support Plans',
      deactivatedIpp: 'FR Deactivated Support Plans',
      bulkExportStudentSupportPlans: 'FR Bulk Export Student Support Plans',


      // Student Profile
      generalProfile: 'FR General Profile Configurations',
      supportPlan: 'FR Support Plan',
      ippProfile: 'FR Support Plan Home',
      supportTeam: 'FR Support Team',
      specializedAssessment: 'FR Specialized Assessment',
      goalPlanning: 'FR Goal Planning',

      // Student Profile
      levelsTiersIcons: 'FR Entry Levels, Tiers of Support, Student Card Icons',

      boardView: 'FR Board View',
      goalOverview: 'FR Goal Overview',
      actions: 'FR Actions',
    },
    dialogs: {
      accountSuspendedTitle: 'FR Account Suspended',
      accountSuspendedMessage: 'FR This account is currently suspended. Please contact wecollab@jigsawlearning.ca.',

      returnStudentToActiveRosterMessage: 'FR Are you sure you want to return this student(s) to the active roster?',
      deleteStudentsTitle: 'FR Delete Student(s)',
      deleteStudentMessage: 'FR Do you wish to permanently delete this student(s)?',

      resourceCreated: 'FR Resource Created',
      resourceUpdated: 'FR Resource Updated',
      resourceNotFound: 'FR Resource not found',

      addComment: 'FR Add Comment',
      editComment: 'FR Edit Comment',

      addSupport: 'FR Add New Support',
      updateSupport: 'FR Update Support',

      logoutTitle: 'FR Log Out',
      logoutMessage: 'FR Are you sure you want to log out?',

      deleteBoardCategoryTitle: 'FR Delete Team Board Category',
      deleteBoardCategoryMessage: 'FR Are you sure you do not want to delete this category from this board?',

      addAction: 'FR Add Action',
      updateAction: 'FR Update Action',

      nextSupportReviewDate: 'FR Next Support Review Date',

      meetingNotes: 'FR Meeting Notes',
    },
    panelsTexts: {
      addedBy: 'FR Added by',
      editedBy: 'FR Edited by',
    },
    resolvers: {
      districtNotFound: 'FR District not found',
      schoolNotFound: 'FR School not found',
      districtOrSchoolNotFound: 'FR District or School not found',
      invalidUrl: 'FR Invalid url',
      invalidId: 'FR You provided invalid id in url',
      districtSuspended: 'FR District you\'re trying to access is suspended',
      schoolSuspended: 'FR School account you\'re trying to access is suspended'
    },
    suspended: 'FR Suspended',
    activated: 'FR Activated',
    all: 'FR All',
    meeting: 'FR Meeting',
    emailCopyOfMeetingNotes: 'FR Email Copy Of Meeting Notes',
    noActionAtThisTime: 'FR No action at this time',
    notes: {
      schoolSupportPlanDeactivated: 'FR School Support Plan Module Deactivated',
      noSubGoalsAdded: 'FR There are no Sub-goals added',
      completedAction: 'FR Action completed, you can\'t make changes',
      abandonedAction: 'FR Action abandoned, you can\'t make changes',
    },
    headerNavigation: {
      userSupport: 'FR User Support',
      reportIssue: 'FR Report an Issue',
      shareFeedback: 'FR Share Feedback',
    }
  };
}
