import { Injectable } from '@angular/core';
// services
import { DistrictAndSchoolDataService } from '@shared/services/district-and-school-data.service';
// models
import { CurrentUserModel, CurrentUserPermissions } from '@shared/models/current-user.model';
import { UserRoles } from '@shared/models/user-types.model';
import { Workspaces } from '@shared/models/resource-types.model';

@Injectable({ providedIn: 'root' })
export class AppStateService {
  public currentUserPermissions: CurrentUserPermissions;
  public currentSchoolYear: string; // FORMAT: '2020-2021', '2021-2022'
  public currentUser: CurrentUserModel;
  private _currentDate: Date;

  // Super Admin uses website as this user
  private simulationUserID: number;

  constructor(
    private readonly dataService: DistrictAndSchoolDataService,
  ) {
  }

  public get currentDate(): Date {
    return this._currentDate;
  }

  public set currentDate(value: Date) {
    this._currentDate = value;
  }

  public get currentUserRoleInCurrentSchool(): UserRoles {
    const selectedSchoolID = this.dataService.schoolID;

    switch (this.currentUserPermissions.userType) {
      case Workspaces.ADMIN:
        return UserRoles.Super_Admin;
      case Workspaces.DISTRICT:
        return this.currentUserPermissions.permissions[selectedSchoolID] && this.currentUserPermissions.permissions[selectedSchoolID].role || null;
      case Workspaces.SCHOOL:
        return this.currentUserPermissions.permissions[selectedSchoolID].role;
      default:
        return null;
    }
  }

  public startUserSimulation(userID: number): void {
    this.simulationUserID = userID;
  }

  public stopUserSimulation(): void {
    this.simulationUserID = null;
  }

  public get getSimulationUserID(): number {
    return this.simulationUserID || null;
  }
}
