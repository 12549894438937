// ng
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

// components
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';

// models
import { UserRoles } from '@shared/models/user-types.model';

// guards
import { AuthGuard } from '@shared/guards/auth.guard';
import { NoAuthGuard } from '@shared/guards/no-auth.guard';
import { AdminGuard } from '@shared/guards/admin.guard';
import { DistrictUserGuard } from '@shared/guards/district-user.guard';
import { PermissionGuard } from '@shared/guards/permission.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canLoad: [NoAuthGuard],
    canActivate: [NoAuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canLoad: [
      AuthGuard,
      AdminGuard
    ],
    canActivate: [
      AuthGuard,
      AdminGuard
    ]
  },
  {
    path: 'district/:id',
    loadChildren: () => import('./modules/district/district.module').then(m => m.DistrictModule),
    canLoad: [
      AuthGuard,
      DistrictUserGuard
    ],
    canActivate: [
      AuthGuard,
      DistrictUserGuard
    ]
  },
  {
    path: 'school/:districtId/select-school',
    loadChildren: () => import('./modules/select-school-module/select-school.module').then(m => m.SelectSchoolModule),
    canLoad: [AuthGuard],
    canActivate: [
      AuthGuard,
      PermissionGuard
    ],
    data: {
      permissions: {
        userRoles: [
          UserRoles.Super_Admin
        ],
        blockAdmin: true,
        anythingBut: true
      }
    }
  },
  {
    path: 'school/:districtId/:id',
    loadChildren: () => import('./modules/school/school.module').then(m => m.SchoolModule),
    canLoad: [
      AuthGuard
    ]
  },
  {
    path: 'school/:districtId',
    pathMatch: 'full',
    redirectTo: 'school/:districtId/select-school'
  },
  {
    path: 'ui-components',
    loadChildren: () => import('./ui-demo/ui-components.module').then(m => m.UiComponentsModule)
  },
  {
    path: 'student-support-network',
    loadChildren: () => import('./modules/shared-pages/modules/supports/components/access-student-support-network/access-student-support-network.module').then(m => m.AccessStudentSupportNetworkModule)
  },
  {
    path: 'user-supports',
    loadChildren: () => import('./modules/shared-pages/modules/user-supports/user-supports.module').then(m => m.UserSupportsModule),
    canLoad: [AuthGuard],
    canActivate: [
      AuthGuard,
    ],
    data:{
      isSingleView:true
    }
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

const routerConfig: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'disabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
