<mat-progress-bar
        *ngIf="isLoadingDistrictOrSchoolLevel || loadingLazyModule"
        [class]="'progress-' + baseColorService.colorBeforeNavigation"
        mode="indeterminate">
</mat-progress-bar>

<div *ngIf="isUserSimulationLoading" class="page-loader">
    <mat-spinner diameter="50" strokeWidth="5" class="spinner-theme"></mat-spinner>
</div>

<div *ngIf="authService.isAuthenticated; else anonymousUser" appInactivityCheck>
    <router-outlet></router-outlet>
</div>
<!---->
<ng-template #anonymousUser>
    <router-outlet></router-outlet>
</ng-template>