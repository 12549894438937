import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum FilterOperators {
  more_than = 'more_than',
  less_than = 'less_than',
  equal = 'equal',
  not_equal = 'not_equal',
  contain = 'contain',
  not_contain = 'not_contain'
}

export function getFilterOperatorDisplayValues(translationService: TranslationService): {[key in FilterOperators]: string} {
  return {
    [FilterOperators.more_than]: translationService.translate(`filterOperators.${FilterOperators.more_than}`),
    [FilterOperators.less_than]: translationService.translate(`filterOperators.${FilterOperators.less_than}`),
    [FilterOperators.equal]: translationService.translate(`filterOperators.${FilterOperators.equal}`),
    [FilterOperators.not_equal]: translationService.translate(`filterOperators.${FilterOperators.not_equal}`),
    [FilterOperators.contain]: translationService.translate(`filterOperators.${FilterOperators.contain}`),
    [FilterOperators.not_contain]: translationService.translate(`filterOperators.${FilterOperators.not_contain}`),
  };
}
