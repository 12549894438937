// Revue Required
import { Injectable } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, NavigationStart } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { getUrlFirstFragment } from '../utils/get-url-first-fragment';

@Injectable({ providedIn: 'root' })
export class BaseColorService {

  private readonly colorsList = {
    auth: 'blue',
    admin: 'green',
    district: 'orange',
    school: 'blue',
    'student-support-network': 'default',
    'user-supports': 'default',
  };

  public colorBeforeNavigation = 'green';
  private currentColor = 'green';

  constructor(private router: Router) {
    router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url),
      )
      .subscribe(
        (url: string): void => {
          this.currentColor = this.colorsList[getUrlFirstFragment(url.split('?')[0])];
          document.documentElement.style.setProperty('--main', `var(--${this.currentColor})`);
          document.documentElement.style.setProperty('--main-light', `var(--${this.currentColor}-light)`);
        }
      );

    router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        map((event: NavigationStart) => event.url),
      )
      .subscribe(
        (eventUrl: string): void => {
          // Get url from event only it it's first time app loads
          const url = this.router.url === '/' ? eventUrl : this.router.url;
          this.colorBeforeNavigation = this.colorsList[getUrlFirstFragment(url.split('?')[0])];
        }
      );
  }

  public get getCurrentColor(): string {
    return this.currentColor;
  }
}
