import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other'
}

export function getGenderDisplayValues(translationService?: TranslationService): {[key in Gender]: string} {
  if (translationService) {
    return {
      [Gender.MALE]: translationService.translate(`genders.${Gender.MALE}`),
      [Gender.FEMALE]: translationService.translate(`genders.${Gender.FEMALE}`),
      [Gender.OTHER]: translationService.translate(`genders.${Gender.OTHER}`)
    };
  } else {
    return {
      [Gender.MALE]: 'Male',
      [Gender.FEMALE]: 'Female',
      [Gender.OTHER]: 'Other'
    };
  }
}
