import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

// services
import { TrackRequestsService } from '@shared/services/track-requests.service';

@Injectable()
export class WaitForSamePageRequestsInterceptor implements HttpInterceptor {
  constructor(
    private readonly trackRequestsService: TrackRequestsService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.trackRequestsService.requestedURLs.add(req.url);

    return next.handle(req)
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            if (this.trackRequestsService.requestedURLs.has(req.url)) {
              this.trackRequestsService.requestedURLs.delete(req.url);
            }

            if (this.trackRequestsService.requestedURLs.size === 0) {
              this.trackRequestsService.allRequestsCompleted$.next();
            }
          }
        }),
        catchError((error: any) => {
          if (this.trackRequestsService.requestedURLs.has(req.url)) {
            this.trackRequestsService.requestedURLs.delete(req.url);
          }

          if (this.trackRequestsService.requestedURLs.size === 0) {
            this.trackRequestsService.allRequestsCompleted$.next();
          }

          return throwError(error);
        })
      );
  }
}
