import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum StudentCardDisplayTypes {
  name = 'name',
  minimal_name_bar = 'minimal_name_bar',
  picture = 'picture',
  name_picture = 'name_picture',
  name_picture_data = 'name_picture_data',
  name_data = 'name_data',
}

export function getStudentCardTypesDisplayValues(translationService?: TranslationService): {[key in StudentCardDisplayTypes]: string} {
  if (translationService) {
    return {
      [StudentCardDisplayTypes.name]: translationService.translate(`studentCardDisplayTypes.${StudentCardDisplayTypes.name}`),
      [StudentCardDisplayTypes.minimal_name_bar]: translationService.translate(`studentCardDisplayTypes.${StudentCardDisplayTypes.minimal_name_bar}`),
      [StudentCardDisplayTypes.picture]: translationService.translate(`studentCardDisplayTypes.${StudentCardDisplayTypes.picture}`),
      [StudentCardDisplayTypes.name_picture]: translationService.translate(`studentCardDisplayTypes.${StudentCardDisplayTypes.name_picture}`),
      [StudentCardDisplayTypes.name_picture_data]: translationService.translate(`studentCardDisplayTypes.${StudentCardDisplayTypes.name_picture_data}`),
      [StudentCardDisplayTypes.name_data]: translationService.translate(`studentCardDisplayTypes.${StudentCardDisplayTypes.name_data}`),
    };
  } else {
    return {
      [StudentCardDisplayTypes.name]: 'Name Only',
      [StudentCardDisplayTypes.minimal_name_bar]: 'Minimal Name Bar',
      [StudentCardDisplayTypes.picture]: 'Picture Only',
      [StudentCardDisplayTypes.name_picture]: 'Name and Picture',
      [StudentCardDisplayTypes.name_picture_data]: 'Name, Picture and Data',
      [StudentCardDisplayTypes.name_data]: 'Name and Data',
    };
  }
}
