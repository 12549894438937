export enum MeetingPanelTypes {
  boardsSchools = 'boardsSchools',
  collaborativeSchools = 'collaborativeSchools',
  schoolProfile = 'schoolProfile',
  boardsStudentsIntegrated = 'boardsStudentsIntegrated',
  boardsStudentsDesegregated = 'boardsStudentsDesegregated',
  collaborativeStudents = 'collaborativeStudents',

  // School level
  boardsSchoolLevel = 'boards',
  collaborativeSchoolLevel = 'collaborativeSchoolLevel'
}
