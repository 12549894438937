import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum CategoryResultTypes {
  manual = 'manual',
  dropdown = 'dropdown'
}

export type StudentDataResultTypesEnumMap = {
  [P in CategoryResultTypes]: string;
};

export function getResultTypeDisplayValues(translationService?: TranslationService): StudentDataResultTypesEnumMap {
  if (translationService) {
    return {
      [CategoryResultTypes.manual]: translationService.translate(`studentDataResultTypes.${CategoryResultTypes.manual}`),
      [CategoryResultTypes.dropdown]: translationService.translate(`studentDataResultTypes.${CategoryResultTypes.dropdown}`)
    };
  } else {
    return {
      [CategoryResultTypes.manual]: 'Manual',
      [CategoryResultTypes.dropdown]: 'Dropdown'
    };
  }
}
