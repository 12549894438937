// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// material
import { MatDialog } from '@angular/material/dialog';
// services
import { BaseHttpService } from '@shared/services/base-http.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { AppStateService } from '@shared/services/app-state.service';
import { TranslationService } from '@shared/modules/translations/services/translation.service';
// models
import { CurrentUserModel } from '@shared/models/current-user.model';
import { Workspaces } from '@shared/models/resource-types.model';
import { ISignInResponse } from "@auth/models/sign-in-response.interface";
import { Languages } from '@shared/modules/translations/models/languages.enum';
import {environmentSocial} from "../../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseHttpService<any> {

  private readonly authAdminUrl = 'admin_auth';
  private readonly authUrl = 'auth';
  private readonly authMicrosoftUrl = 'microsoft_login';
  private readonly authGoogleUrl = 'google_login';
  private readonly passUrl = 'users/password';
  private readonly CURRENT_USER_URL = 'current_user';

  constructor(
    http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly appStateService: AppStateService,
    private readonly translationService: TranslationService,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) {
    super(http);
  }

  public signIn(email: string, password: string): Observable<any> {

    return this.http.post(`${this.HOST}/${this.authUrl}`, { email, password }).pipe(
      tap(res => this.localStorageService.setToken(res.token))
    );
  }

  public signInAdmin(email: string, password: string): Observable<any> {
    return this.http.post(`${this.HOST}/${this.authAdminUrl}`, { email, password }).pipe(
      tap(res => this.localStorageService.setToken(res.token))
    );
  }

  public signInMicrosoft(token, userType: 'user' | 'admin'): Observable<ISignInResponse> {
    return this.http.post(`${this.HOST}/${this.API_V1}/${this.authMicrosoftUrl}`, { id_token: token, user_type: userType }).pipe(
        tap((res: ISignInResponse) => this.localStorageService.setToken(res.token))
    );
  }

  public signInGoogle(token, userType: 'user' | 'admin'): Observable<ISignInResponse> {
    return this.http.post(`${this.HOST}/${this.API_V1}/${this.authGoogleUrl}`, { id_token: token, user_type: userType }).pipe(
        tap((res: ISignInResponse) => this.localStorageService.setToken(res.token))
    );
  }

  public getCurrentUser(): Observable<CurrentUserModel> {
    return this.getSingle<CurrentUserModel>(this.CURRENT_USER_URL)
      .pipe(
        tap(res => {
          if (!!res.is_super) {
            this.translationService.setLanguage(Languages.EN);
          } else {
            if (!!res.district) {
              this.translationService.setLanguage(!!res.district.francophone ? Languages.FR : Languages.EN);
            } else {
              this.translationService.setLanguage(Languages.EN);
            }
          }
        }),
      );
  }

  public get isAuthenticated(): boolean {
    return !!this.localStorageService.getToken();
  }

  public logout(doNotRedirectToPrevRouteAfterReLogin = false): void {
    this.dialog.openDialogs.map(dialog => dialog.close());

    const redirectAfterLogin = `${window.location.pathname}${window.location.search}`;
    if (redirectAfterLogin.startsWith('/auth')) {
      this.localStorageService.clear();
      return;
    }

    let isAdmin: boolean;

    if (!!this.appStateService.currentUserPermissions) {
      isAdmin = this.appStateService.currentUserPermissions?.userType === Workspaces.ADMIN;
    } else if (!!this.localStorageService.userType) {
      isAdmin = this.localStorageService.userType === Workspaces.ADMIN;
    } else {
      isAdmin = redirectAfterLogin.startsWith('/admin') || redirectAfterLogin.startsWith('/auth/admins');
    }

    this.localStorageService.clear();

    const params = !!redirectAfterLogin && !doNotRedirectToPrevRouteAfterReLogin ? { redirectTo: redirectAfterLogin } : {};

    if (isAdmin) {
      this.router.navigate(['/auth/admins'], { queryParams: params });
    } else {
      this.router.navigate(['/auth'], { queryParams: params });
    }
  }
}
